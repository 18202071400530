import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { LoginSSOFormComponent } from './login-sso-form.component';

@NgModule({
  declarations: [LoginSSOFormComponent],
  imports: [SharedModule, RouterModule],
  exports: [LoginSSOFormComponent],
})
export class LoginSSOFormModule { }

import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { PasswordChangeSuccessComponent } from './password-change-success.component';

@NgModule({
  declarations: [PasswordChangeSuccessComponent],
  imports: [SharedModule],
  exports: [PasswordChangeSuccessComponent],
})
export class PasswordChangeSuccessModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductSelectionComponent } from 'src/app/components/product-selection/product-selection.component';

import { ProductSelectionPageComponent } from './product-selection-page.component';

const routes: Routes = [{
  path: 'product',
  component: ProductSelectionPageComponent,
  children: [
    {
      path: 'authorizationDenied',
      component: ProductSelectionComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductSelectionPageRoutingModule { }

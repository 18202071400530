import { NgModule } from '@angular/core';

import { BrandingModule } from '../../components/branding/branding.module';
import { FooterModule } from '../../components/footer/footer.module';
import { SharedModule } from '../../shared/shared.module';
import { ErrorPageComponent } from './error-page.component';

@NgModule({
  declarations: [ErrorPageComponent],
  imports: [
    SharedModule,
    BrandingModule,
    FooterModule,
  ],
  exports: [ErrorPageComponent],
})
export class ErrorPageModule { }

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from 'ln-templates-angular';
import * as translations from '../../shared/dictionary';
import { AppConfigService, AuthService } from '../../core/services';
import { IBrandData, ITrial } from '../../core/models/auth';

@Component({
  selector: 'wam-signup',
  templateUrl: './signup.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SignupComponent implements OnInit {

  isXLargeScreen$: Observable<boolean>;
  signupTrial: ITrial;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(
    private _observer: BreakpointObserver,
    private configService: AppConfigService,
    private authService: AuthService) {
    this.translations = translations;
  }

  ngOnInit(): void {
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
    this.getSignUpData();
  }

  getSignUpData() {
    const info = this.authService.getMarketingInfo();
    
    if (info && info.authMarketing) {
      this.signupTrial = info.authMarketing.trial;
    }

    this.authService.marketingDataStore.subscribe((response: IBrandData) => {
      if (response) {
        this.signupTrial = response.authMarketing.trial;
      }
    });
  }

  signup(signupUrl: string): void {
    if (!signupUrl) {
      signupUrl =  this.configService.signupUrl;
      if (this.authService.clientData && this.authService.clientData.freeTrialURL) {
        signupUrl = this.authService.clientData.freeTrialURL;
      }
    }
    window.open(signupUrl, '_blank');
  }
}

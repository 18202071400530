import { NgModule } from '@angular/core';

import { OAuthModule } from 'angular-oauth2-oidc';
import { SharedModule } from '../../shared/shared.module';
import { UserProfileComponent } from './user-profile.component';

@NgModule({
  declarations: [UserProfileComponent],
  imports: [
    SharedModule,
    OAuthModule.forRoot(),
  ],
  exports: [UserProfileComponent],
})
export class UserProfileModule { }

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { LoginFormComponent } from './login-form.component';
import { SelfRegistrationModule } from '../self-registration/self-registration.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [LoginFormComponent],
  imports: [SharedModule, RouterModule, SelfRegistrationModule, ReactiveFormsModule],
  exports: [LoginFormComponent],
})
export class LoginFormModule {}

import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { RecoverUserNameSuccessComponent } from './recover-username-success.component';

@NgModule({
  declarations: [RecoverUserNameSuccessComponent],
  imports: [SharedModule],
  exports: [RecoverUserNameSuccessComponent],
})
export class RecoverUserNameSuccessModule { }

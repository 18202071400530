import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';
import { FooterModule } from '../../components/footer/footer.module';
import { BrandingModule } from '../../components/branding/branding.module';
import { AccountActivatePageComponent } from './activate-account-page.component';
import { AccountActivatePageRoutingModule } from './activate-account-page-routing.module';
import { ActivateAccountModule } from '../../components/activate-account/activate-account.module';

@NgModule({
  declarations: [AccountActivatePageComponent],
  imports: [
    SharedModule,
    FormsModule,
    AccountActivatePageRoutingModule,
    ReactiveFormsModule,
    FooterModule,
    BrandingModule,
    ActivateAccountModule
  ],
  exports: [AccountActivatePageComponent],
})
export class AccountActivatePageModule { }

import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { LogoutFormModule } from '../../components/logout/logout-form.module';
import { FooterModule } from '../../components/footer/footer.module';
import { BrandingModule } from '../../components/branding/branding.module';
import { MarketingModule } from '../../components/marketing/marketing.module';
import { SignupModule } from '../../components/signup/signup.module';
import { LogoutPageComponent } from './logout-page.component';

@NgModule({
  declarations: [LogoutPageComponent],
  imports: [
    SharedModule,
    LogoutFormModule,
    FooterModule,
    BrandingModule,
    MarketingModule,
    SignupModule,
  ],
  exports: [LogoutPageComponent],
})
export class LogoutPageModule { }

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from 'ln-templates-angular';
import * as translations from '../../shared/dictionary';
import { AppConfigService, AuthService } from '../../core/services';
import { IBrandData, IExplore } from '../../core/models/auth';

@Component({
  selector: 'wam-marketing',
  templateUrl: './marketing.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class MarketingComponent implements OnInit {
  isXLargeScreen$: Observable<boolean>;
  marketingData: IExplore;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(
    private _observer: BreakpointObserver,
    private configService: AppConfigService,
    private authService: AuthService) {
    this.translations = translations;
    this.getMarketingData();
  }

  getMarketingData() {
    const info = this.authService.getMarketingInfo();
    
    if (info && info.authMarketing) {
      this.marketingData = info.authMarketing.explore;
    }

    this.authService.marketingDataStore.subscribe((response: IBrandData) => {
      if (response) {
        this.marketingData = response.authMarketing.explore;
      }
    });
  }

  ngOnInit(): void {
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
  }

  explore(exploreUrl): void {
    if (!exploreUrl) {
      exploreUrl = this.configService.exploreUrl;
      if (this.authService.clientData && this.authService.clientData.exploreURL) {
        exploreUrl = this.authService.clientData.exploreURL;
      }
    }
    window.open(exploreUrl, '_blank');
  }
}

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';
import { FooterModule } from '../../components/footer/footer.module';
import { BrandingModule } from '../../components/branding/branding.module';
import { ProductSelectionPageComponent } from './product-selection-page.component';
import { ProductSelectionPageRoutingModule } from './product-selection-page-routing.module';
import { ProductSelectionModule } from 'src/app/components/product-selection/product-selection.module';

@NgModule({
  declarations: [ProductSelectionPageComponent],
  imports: [
    SharedModule,
    FormsModule,
    ProductSelectionPageRoutingModule,
    ReactiveFormsModule,
    FooterModule,
    BrandingModule,
    ProductSelectionModule
  ],
  exports: [ProductSelectionPageComponent],
})
export class PrpductSelectionPageModule { }

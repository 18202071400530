import { Injectable } from '@angular/core';
import {switchMap} from "rxjs/operators";
import {Observable} from "rxjs";
import {IClientData} from "../models/auth";
import * as translations from '../../shared/dictionary';
import {AuthService} from "./auth.service";
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  logo = translations.Branding_Logo;
  clientData$: Observable<IClientData>;

  constructor(private authService: AuthService,
              private route: ActivatedRoute){}

  public getLogoText(aci: string,locale: string): string {
    const data = JSON.parse(sessionStorage.getItem('clientData'));
    if (this.authService.clientData && this.authService.clientData.cn) {
      this.logo = this.authService.clientData.cn;
    } else if (data && !aci && !locale) {
      this.authService.clientData = data;
      this.logo = data.cn;
    } else {
      // Get client info

      // If url has aci and locale use that, else use default value
      this.clientData$ = this.route.queryParams.pipe(
        switchMap((params, index) => this.authService.setClientData(params['aci'], params['locale'])));

      this.clientData$.subscribe(clientData => {
        if (clientData && clientData.cn) {
          this.logo = clientData.cn;
        }
      })
    }
    console.log(this.logo);
    return this.logo;
  }

}

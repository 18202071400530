import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { OAuthModule } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LoginFormModule } from './components/login/login-form.module';
import { LoginPageModule } from './pages/login/login-page.module';
import { LogoutPageModule } from './pages/logout/logout-page.module';
import { LogoutFormModule } from './components/logout/logout-form.module';
import { LogoutSuccessPageModule } from './pages/logout-success/logout-success-page.module';
import { ErrorPageModule } from './pages/error/error-page.module';
import { PasswordPageModule } from './pages/password/password-page.module';
import { AccountActivatePageModule } from './pages/activate-account/activate-account-page.module';
import { LoginSSOPageModule } from './pages/login-sso/login-sso-page.module';
import { LoginSSOFormModule } from './components/login-sso/login-sso-form.module';
import { PrpductSelectionPageModule } from './pages/product-selection/product-selection-page.module';
import { CookieService } from 'ngx-cookie-service';
import { DashboardUserModule } from './pages/dashboard/dashboard-page.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    PasswordPageModule,
    PrpductSelectionPageModule,
    DashboardUserModule,
    AccountActivatePageModule,
    AppRoutingModule,
    ErrorPageModule,
    LoginPageModule,
    LoginFormModule,
    LogoutPageModule,
    LogoutFormModule,
    LogoutSuccessPageModule,
    LoginSSOPageModule,
    LoginSSOFormModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpClient) => new TranslateHttpLoader(HttpClient),
        deps: [HttpClient],
      },
    }),
    OAuthModule.forRoot(),
  ],
  providers: [CookieService],
  bootstrap: [AppComponent],
})
export class AppModule {}

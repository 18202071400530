import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { ActivateAccountComponent } from './activate-account.component';

@NgModule({
  declarations: [ActivateAccountComponent],
  imports: [SharedModule],
  exports: [ActivateAccountComponent],
})
export class ActivateAccountModule { }

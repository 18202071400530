import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from 'ln-templates-angular';

import * as translations from '../../shared/dictionary';
import { AuthService } from '../../core/services';
import { IAuthMarketing, IBrandData } from '../../core/models/auth';

@Component({
  selector: 'wam-login-sso-page',
  templateUrl: './login-sso-page.component.html',
  styleUrls: ['./login-sso-page.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginSSOPageComponent implements OnInit, OnDestroy {
  isXLargeScreen$: Observable<boolean>;
  authMarketing: IAuthMarketing;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(
    private _observer: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private authService: AuthService) {
    this.translations = translations;
    this.setTitle();
    this.setMetaTag();
  }

  ngOnInit(): void {
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
    this.setAuthMarketing();
  }

  setAuthMarketing() {
    const info = this.authService.getMarketingInfo();

    if (info) {
      this.authMarketing = info.authMarketing;
    }

    this.authService.marketingDataStore.subscribe((response: IBrandData) => {
      if (response) {
        this.authMarketing = response.authMarketing;
      }
    });
  }

  signUp(): void {
    console.log('Customer wants to sign up');
  }

  setTitle(): void {
    const productName = 'Lexis®';
    const pageTitleSuffix = 'Sign In | LexisNexis';
    const title = `${productName} - ${pageTitleSuffix}`;
    this.titleService.setTitle(title);
  }

  setMetaTag(): void {
    this.metaService.addTag(
      {
        name: 'description',
        content: this.translations.Login_Description_Meta_Tag,
      });
  }


  ngOnDestroy(): void {
    this.metaService.removeTag('name=description');
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginPageComponent } from './pages/login/login-page.component';
import { ErrorPageComponent } from './pages/error/error-page.component';
import { AuthGuard } from './core/guards/auth-guard.service';
import { PasswordPageComponent } from './pages/password/password-page.component';
import { LoginSSOPageComponent } from './pages/login-sso/login-sso-page.component';
import { ProductSelectionPageComponent } from './pages/product-selection/product-selection-page.component';
import { DashboardPageComponent } from './pages/dashboard/dashboard-page.component';
import { LogoutPageComponent } from './pages/logout/logout-page.component';
import { LogoutSuccessPageComponent } from './pages/logout-success/logout-success-page.component';
import { AbeFlagResolverService } from './core/guards/able-flag-resolver.service';
import { AciExcludeAbeFlagResolverService } from './core/guards/aci-exclude-abe-flag-resolver.service';


const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [AuthGuard],
    resolve: {abeFlagEvaluationValue: AbeFlagResolverService, aciExcludeFlagEvaluationValue: AciExcludeAbeFlagResolverService}
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
  },
  {
    path: 'logout-success',
    component: LogoutSuccessPageComponent,
  },
  {
    path: 'login-sso',
    component: LoginSSOPageComponent,
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'password',
    component: PasswordPageComponent,
  },
  {
    path: 'product',
    component: ProductSelectionPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user',
    component: DashboardPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';

import { IErrorData } from '../models/auth';
import * as translations from '../../shared/dictionary';

@Injectable()
export class ErrorService {
  defaultMessage: string;
  inlineError: ReplaySubject<string> = new ReplaySubject<string>(1);  // TODO: update with values from UI
  error: ReplaySubject<IErrorData> = new ReplaySubject<IErrorData>(1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(private router: Router) {
    this.translations = translations;
  }

  /**
   * Gets the error response, created error message, and redirects based on error code
   *
   * @param defaultMessage string
   * @param inlineError ReplaySubject<string>
   * @param error ReplaySubject<IErrorData>
   * @param errorRsp
   */
  public getErrorMessage(errorRsp: IErrorData): void {
    // Most 400 level codes display an inline error message
    if (errorRsp.status === 401 || errorRsp.status === 403) {

      if (errorRsp.status === 401) {
        this.defaultMessage = this.translations.DefaultErrorMsg401;
      }

      if (errorRsp.status === 403) {
        switch (errorRsp.error && errorRsp.error.errorCode) {
          case '401.1':
            this.defaultMessage = this.translations.DefaultErrorMsg401_1;
            break;
          case '403.1':
            this.defaultMessage = this.translations.DefaultErrorMsg403_1;
            break;
          case '403.3':
            this.defaultMessage = this.translations.DefaultErrorMsg403_3;
            break;
          case '403.4':
            this.defaultMessage = this.translations.DefaultErrorMsg403_4;
            break;
          case '403.5':
            this.defaultMessage = this.translations.DefaultErrorMsg403_5;
            break;
          case '403.6':
            this.defaultMessage = this.translations.DefaultErrorMsg403_6;
            break;
          case '403.7':
            this.defaultMessage = this.translations.DefaultErrorMsg403_7;
            break;
          case '403.8':
            this.defaultMessage = this.translations.DefaultErrorMsg403_8;
            break;
          case '403.9':
            this.defaultMessage = this.translations.DefaultErrorMsg403_9;
            break;
          case '403.10':
            this.defaultMessage = this.translations.DefaultErrorMsg403_10;
            break;
          case '403.11':
            this.defaultMessage = this.translations.DefaultErrorMsg403_11;
            break;
          case '403.12':
            this.defaultMessage = this.translations.DefaultErrorMsg403_12;
            break;
          case undefined:
          default:
            // redirect to error page if there is no custom inline message
            this.error.next(errorRsp);
            this.router.navigate(['error'], { queryParams: { code: '403' } });
        }
      }

      this.inlineError.next(this.defaultMessage);

    } else {
      // Server Error - redirects to error page (This covers 4xx errors not listed above, and 5xx level errors)
      // The component will display the correct message based on the status or code passed in a query parameter.
      // The query parameter keeps the messaging consistent if the error page is refreshed
      this.error.next(errorRsp);

      let code = errorRsp.status;

      if (errorRsp.error && errorRsp.error.errorCode) {
        // eslint-disable-next-line
        code = parseInt(errorRsp.error.errorCode);
      }

      this.router.navigate(['error'], { queryParams: { code: String(code) } });
    }
  }

  public isRequiresSupportMessage(errorRsp: IErrorData): boolean {
    if (errorRsp.status === 403) {
      switch (errorRsp.error && errorRsp.error.errorCode) {
        case '403.10':
          return true;
        case '403.12':
          return true;
        case undefined:
          return false;
        default:
          return false;
      }
    }
    return false;
  }

  public  redirectToAccessUrl(errorResponse: IErrorData): void {
    const redirectUrl = errorResponse.error.redirectUrl;
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PasswordPageComponent } from './password-page.component';
import { ResetPasswordComponent } from '../../components/reset-password/reset-password.component';
import { CreatePasswordComponent } from '../../components/create-password/create-password.component';
import { PasswordCreatedSuccessComponent } from '../../components/password-created-success/password-created-success.component';
import { ActivateAccountComponent } from '../../components/activate-account/activate-account.component';
import { ChangePasswordComponent } from 'src/app/components/change-password/change-password.component';
import { PasswordChangeSuccessComponent } from 'src/app/components/change-password-success/password-change-success.component';
import { RecoverUserNameComponent } from 'src/app/components/recover-username/recover-username.component';
import { RecoverUserNameSuccessComponent } from 'src/app/components/recover-username-success/recover-username-success.component';
import { UserProfileComponent } from 'src/app/components/user-profile/user-profile.component';
import {SignInProfileSuccessComponent} from "../../components/signin-profile-success/signin-profile-success.component";

const routes: Routes = [{
  path: 'password',
  component: PasswordPageComponent,
  children: [
    {
      path: 'reset',
      component: ResetPasswordComponent,
    },
    {
      path: 'create',
      component: CreatePasswordComponent,
    },
    {
      path: 'success',
      component: PasswordCreatedSuccessComponent,
    },
    {
      path: 'activate',
      component: ActivateAccountComponent,
    },
    {
      path: 'change',
      component: ChangePasswordComponent,
    },
    {
      path: 'change/success',
      component: PasswordChangeSuccessComponent,
    }
  ],
},
{
  path: 'username',
  component: PasswordPageComponent,
  children: [
    {
      path: 'recover',
      component: RecoverUserNameComponent,
    },
    {
      path: 'recoversuccess',
      component: RecoverUserNameSuccessComponent,
    },
  ]
},
{
  path: 'userprofile',
  component: PasswordPageComponent,
  children: [
    {
      path: 'update',
      component: UserProfileComponent,
    },
    {
      path: 'success',
      component: SignInProfileSuccessComponent,
    },
    {
      path: 'change',
      component: ChangePasswordComponent,
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PasswordPageRoutingModule { }

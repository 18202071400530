import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as translations from '../../shared/dictionary';
import { AuthService } from '../../core/services/auth.service';
import { IClientData } from '../../core/models/auth';

@Component({
  selector: 'wam-branding',
  templateUrl: './branding.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class BrandingComponent {
  logo = translations.Branding_Logo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  /**
   * Client data
   */
  clientData$: Observable<IClientData>;
  locale: any;
  aci: any;

  constructor(private authService: AuthService, private route: ActivatedRoute) {
    this.translations = translations;
    this.route.queryParams.subscribe((params) => {
      this.locale = params['locale'];
      this.aci = params['aci'];
      this.setLogoText();
    });
  }

  private setLogoText(): void {
    const data = JSON.parse(sessionStorage.getItem('clientData'));
    if (this.authService.clientData && this.authService.clientData.cn) {
      this.logo = this.authService.clientData.cn;
    } else if (data && !this.aci && !this.locale) {
      this.authService.clientData = data;
      this.logo = data.cn;
    } else {
      // Get client info

      // If url has aci and locale use that, else use default value
      this.clientData$ = this.route.queryParams.pipe(
        switchMap((params, index) => this.authService.setClientData(params['aci'], params['locale'])));

      this.clientData$.subscribe(clientData => {
        if (clientData && clientData.cn) {
          this.logo = clientData.cn;
        }
      })
    }
  }
}

import { Component, ViewEncapsulation } from '@angular/core';

import {AuthDataService, AuthService} from '../../core/services';
import * as translations from '../../shared/dictionary';
import {switchMap} from "rxjs/operators";
import {Observable} from "rxjs";
import {IClientData} from "../../core/models/auth";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'wam-product-selection-page',
  templateUrl: './product-selection-page.component.html',
  styleUrls: ['./product-selection-page.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductSelectionPageComponent {
  title: string;
  description: string;
  productTitle: string;
  logo = translations.Branding_Logo;

  /**
   * text translations
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any;

  clientData$: Observable<IClientData>;
  locale: any;
  aci: any;

  constructor(private authDataService: AuthDataService,
              private route: ActivatedRoute,
              private authService: AuthService) {
    this.translations = translations;
    this.route.queryParams.subscribe((params) => {
      this.locale = params['locale'];
      this.aci = params['aci'];
      this.setLogoText();
    });
    this.setLogoText()
    this.setTitleBlock();
  }
  private setLogoText(): void {
    const data = JSON.parse(sessionStorage.getItem('clientData'));
    if (this.authService.clientData && this.authService.clientData.cn) {
      this.logo = this.authService.clientData.cn;
    } else if (data && !this.aci && !this.locale) {
      this.authService.clientData = data;
      this.logo = data.cn;
    } else {
      // Get client info
      // If url has aci and locale use that, else use default value
      this.clientData$ = this.route.queryParams.pipe(
        switchMap((params, index) => this.authService.setClientData(params['aci'], params['locale'])));

      this.clientData$.subscribe(clientData => {
        if (clientData && clientData.cn) {
          this.logo = clientData.cn;
        }
      })
    }
  }
  setTitleBlock(): void {
    this.authDataService.title.subscribe((title: string) => {
      this.title = title;
    });

    this.authDataService.productTitle.subscribe((productTitle: string) => {
      this.productTitle = productTitle;
    });

    this.authDataService.description.subscribe((description: string) => {
      this.description = description;
    })

  }

}

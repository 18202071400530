/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, ErrorService, AuthDataService, AppConfigService } from '../../core/services';
import { authLogoutConfigFactory } from '../../core/services/auth-logout-config.factory';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Component({
  selector: 'wam-logout',
  templateUrl: './logout-form.component.html',
  styleUrls: ['./logout-form.component.less'],
  encapsulation: ViewEncapsulation.None,
})

export class LogoutFormComponent implements OnInit {
  isXLargeScreen$: Observable<boolean>;
  loginChecked = false;
  baseUrl = '';

  constructor(
    private router: Router,
    private oauthService: OAuthService,
    @Inject(DOCUMENT) private document: Document,
    private configService: AppConfigService) {
    this.oauthService.configure(authLogoutConfigFactory(this.configService));
    this.oauthService.loadDiscoveryDocumentAndLogin().then(() => {
      if (this.oauthService.hasValidAccessToken()  && this.oauthService.hasValidIdToken()) {
        this.loginChecked = true;
        this.logout();
      }
    });
  }

  ngOnInit(): void {
  }

  logout() {
    this.oauthService.postLogoutRedirectUri = `${this.document.baseURI  }logout-success`;
    this.oauthService.logOut();
  }

}

import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { RecoverUserNameComponent } from './recover-username.component';

@NgModule({
  declarations: [RecoverUserNameComponent],
  imports: [SharedModule],
  exports: [RecoverUserNameComponent],
})
export class RecoverUserNameModule { }

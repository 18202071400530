import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'wam-logout-page',
  templateUrl: './logout-success-page.component.html',
  styleUrls: ['./logout-success-page.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LogoutSuccessPageComponent {

  constructor() {
  }

}

import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  AppConfigService,
  AppInitializerProvider,
  AuthService,
  AuthDataService,
  ErrorService,
} from './services';
import { ErrorInterceptor } from './http-interceptors/http-error.interceptor';
import {AppDataService} from "./services/app-data.service";

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AppConfigService,
    AppInitializerProvider,
    AuthService,
    ErrorService,
    AuthDataService,
    AppDataService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}

import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { SelfRegistrationComponent } from './self-registration.component';

@NgModule({
  declarations: [SelfRegistrationComponent],
  imports: [SharedModule],
  exports: [SelfRegistrationComponent],
})
export class SelfRegistrationModule {
}

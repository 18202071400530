import { Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../core/services/auth.service';
import * as translations from '../../shared/dictionary';

export const Breakpoints = {
  SMALL: '360px',
  MEDIUM: '600px',
  LARGE: '1024px',
  XLARGE: '1440px',
};

@Component({
  selector: 'wam-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorPageComponent {
  customerCareNumber: string;
  status = 400;
  errorImgPath = '/assets/images/display/400.svg';
  errorNote = translations.ErrorNote_Default;
  errorDescription = translations.ErrorDescription_Default;
  errorSuggestion = translations.ErrorSuggestion_Default;
  year: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;
  isLargeScreen$: Observable<boolean>;
  showCustomerCareNumber = false;

  constructor(
    private _observer: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private authService: AuthService) {
    this.translations = translations;
    this.year = new Date().getFullYear();
    this.setTitle();
    this.setCustomerCareNumber();
  }

  ngOnInit(): void {
    // check for the error code as a query parameter
    this.route.queryParams.subscribe((params) => {
      if (params.code >= '500') {
        this.status = 500;
        this.errorNote = this.translations.ErrorNote_500;
        this.errorDescription = this.translations.ErrorDescription_500;
        this.errorSuggestion = this.translations.ErrorSuggestion_500;
      } else if (params.code === '403') {
        this.status = 403;
        this.errorNote = this.translations.ErrorNote_403;
        this.errorDescription = this.translations.ErrorDescription_403;
        this.errorSuggestion = this.translations.ErrorSuggestion_403;
      } else if (params.code === '468') {
        this.status = 400;
        this.errorNote = this.translations.ErrorNote_468;
        this.errorDescription = this.translations.ErrorDescription_468;
        this.errorSuggestion = this.translations.ErrorSuggestion_468;
        this.showCustomerCareNumber = true;
      } else if (params.code === '476' || params.code === '455') {
        this.status = 400;
        this.errorNote = this.translations.ErrorNote_476;
        this.errorDescription = this.translations.ErrorDescription_476;
        this.errorSuggestion = this.translations.ErrorSuggestion_476;
        this.showCustomerCareNumber = true;
      } else {
        this.errorNote = this.translations.ErrorNote_Default;
        this.errorDescription = this.translations.ErrorDescription_Default;
        this.errorSuggestion = this.translations.ErrorSuggestion_Default;
      }
    });

    this.errorImgPath = `/assets/images/display/${  this.status  }.svg`;

    this.isLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.LARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
  }
 returnSignIn(): void {
    if (this.isNullUndefinedOrEmptyString(this.authService.loginChallenge)) {
      this.navigateToLandingPage();
      return;
    }
    //If the login challenge exists, forward to the auth ui sign in
    this.navigateToLogin();
  }
  isNullUndefinedOrEmptyString(value: string){
    return (value == null || value.length === 0 || 'null' === value || 'undefined' === value);
  }
  navigateToLogin() {
    this.router.navigate(
      ['login'],
      {
        queryParams: {
          login_challenge: this.authService.loginChallenge,
        }
      },
    );
  }
  navigateToLandingPage() {
    //Redirect the user to the landing page which will forward to the login page if not authenticated.
    const signInUrl = this.authService.clientData.landingPageURL;
    this.authService.redirect(signInUrl);
  }

  private setCustomerCareNumber() {
    if (this.authService.clientData && this.authService.clientData.csPhoneNumber) {
      this.customerCareNumber = this.authService.clientData.csPhoneNumber;
    }
  }

  private setTitle() {
    const productName = 'Lexis® Create';
    this.titleService.setTitle(productName);
  }
}

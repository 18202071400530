import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from 'ln-templates-angular';

import * as translations from '../../shared/dictionary';
import { AuthService, ErrorService, AuthDataService } from '../../core/services';
import { IErrorData, IForgotPasswordData } from '../../core/models/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {LoginForm_emptyEmail, Recover_Username_Invalid_Email} from "../../shared/dictionary";

@Component({
  selector: 'wam-recover-username',
  templateUrl: './recover-username.component.html',
  styleUrls: ['./recover-username.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class RecoverUserNameComponent implements OnInit, OnDestroy {
  isXLargeScreen$: Observable<boolean>;
  username: string;
  inlineErrorDisplay: string;
  locale: string;
  aci: string;
  customerCareNumber: string;
  showCustomerCareNumber : boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(
    private _observer: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private authService: AuthService,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private location: Location,
    private authDataService: AuthDataService,
    private router: Router) {
    this.translations = translations;
    this.setTitle();
    this.setMetaTag();
    this.setTitleBlock();
  }

  ngOnInit(): void {
    this.setCustomerCareNumber();
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );

      this.route.queryParams.subscribe((params) => {
        this.locale = params['locale'];
        this.aci = params['aci'];
      });
  }

  setTitle(): void {
    const productName = 'Lexis®';
    const pageTitleSuffix = 'Recover username | LexisNexis';
    const title = `${productName} - ${pageTitleSuffix}`;
    this.titleService.setTitle(title);
  }

  setMetaTag(): void {
    this.metaService.addTag(
      {
        name: 'description',
        content: this.translations.Login_Description_Meta_Tag,
      });
  }

  resetUsername() {
    this.inlineErrorDisplay = '';
    this.authService.forgetUsername(this.username, this.locale, this.aci)
      .subscribe(response => {
        this.router.navigate(['/username/recoversuccess']);
      }, (err: IErrorData) => {
        if (err && err.error && err.error.errorCode) {
          this.showCustomerCareNumber = this.errorService.isRequiresSupportMessage(err);
          const code: string = err.error.errorCode;
          const errorCodes = ['472', '473', '474', '475', '476', '477', '478', '462'];
          if (code === '422') {
            this.inlineErrorDisplay = translations.LoginForm_emptyEmail;
          } else if (code === '425') {
            this.inlineErrorDisplay = translations.Recover_Username_Invalid_Email;
          } else if (errorCodes.includes(code)) {
            this.inlineErrorDisplay = translations.Reset_Password_InValid_UserName;
          }
        }
      });
  }

  setTitleBlock(): void {
    this.authDataService.setTitle(this.translations.Recover_Password_Header);
    this.authDataService.setDescription(this.translations.Recover_Password_Desc);
  }

  back() {
    this.authService.redirect(this.authService.getsignInPageUrl());
  }

  private setCustomerCareNumber() {
    if (this.authService.clientData && this.authService.clientData.csPhoneNumber) {
      this.customerCareNumber = this.authService.clientData.csPhoneNumber;
    }
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=description');
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Breakpoints } from 'ln-templates-angular';
import { map } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import * as translations from '../../shared/dictionary';
import { AuthService } from '../../core/services';
import { IBrandData, ISelfRegistration } from '../../core/models/auth';


@Component({
  selector: 'wam-self-registration',
  templateUrl: './self-registration.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SelfRegistrationComponent implements OnInit {
  isXLargeScreen$: Observable<boolean>;
  selfRegistrationData: ISelfRegistration;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(
    private _observer: BreakpointObserver,
    private authService: AuthService) {
    this.translations = translations;
    this.getSelfRegistrationData();
  }

  getSelfRegistrationData() {
    const marketingInfo = this.authService.getMarketingInfo();

    if (marketingInfo && marketingInfo.selfRegistration
        && marketingInfo.selfRegistration.enabled) {
      this.selfRegistrationData = marketingInfo.selfRegistration;
    }
    this.authService.marketingDataStore.subscribe((response: IBrandData) => {
      if (response) {
        this.selfRegistrationData = response.selfRegistration;
      }
    });

  }

  ngOnInit(): void {
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
  }
  selfRegister(url): void {
    window.open(url, '_blank');

  }
}

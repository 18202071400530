import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  AccessTokenInfo,
  IAccountActivationData,
  IAppContextData,
  IBrandData,
  IChangePasswordData,
  IClientData,
  ICreatePasswordData,
  IForgotPasswordData,
  IHydraClient,
  ILogin,
  IRedirectData,
  ISetPasswordData,
  IUpdate,
  IUserData,
  IUserProductSubscriptions,
  IAuthMarketingDetails,
  ABEResponse,
} from '../models/auth';
import { AppConfigService } from './app-config.service';
import { IpService } from './ip.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * oidc url for evaluation API call
   */
  private readonly baseOidcApiUrl: string;

  /**
   * users api url for evaluation API call
   */
  private readonly baseUsersApiUrl: string;

  /**
   * app context data
   */
  private _appContextData: IAppContextData;

  /**
   * Client data
   */
  private _clientData: IClientData;

  /**
   * Login challenge
   */
  private _challenge: string;

  /**
   * Emits page description
   */
  public marketingDataStore = new BehaviorSubject<IBrandData>(null);

  constructor(private configService: AppConfigService, private ipService: IpService, private http: HttpClient) {
    this.baseOidcApiUrl = `${this.configService.oidcApiUrl}`;
    this.baseUsersApiUrl = `${this.configService.usersApiUrl}`;
  }

  /**
   * Checks if there is an active session associated with the login challenge
   * If yes, redirects user based on the response
   *
   * If no, we expect app context value to be available in the response.
   * read those value and allow the guard to display the sign-in screen.
   *
   * @param {string} challenge the login challenge
   * @returns {boolean} indicator if login challenge validation was success
   */
  public validateLoginChallenge(challenge: string): Observable<boolean> {
    this.loginChallenge = challenge;
    const url = `${this.baseOidcApiUrl}/login/challenge?login_challenge=${challenge}`;
    return this.http.get<IRedirectData>(url).pipe(
      map((response: IRedirectData) => {
        if (response.redirectUrl) {
          this.redirect(response.redirectUrl);
        }

        return false;
      }),
      catchError((err) => {
        if (err && err.error && err.error.errorCode === '401') {
          this.appContextData = err.error as IAppContextData;
          this.ipService.getIpAddress().subscribe((data) => {
            this.getBrand(this.appContextData.ui_locales, this.appContextData.appContextId, data.ip).subscribe((res) => {
              this.marketingDataStore.next(res);
              sessionStorage.setItem('brandData', JSON.stringify(res));
            });
          });
          return this.getClientData(this.appContextData).pipe(
            map((response: IClientData) => {
              this.clientData = response;
              return true;
            })
          );
        }

        return throwError(err);
      })
    );
  }

  /**
   * Get client data
   *
   * @param {IAppContextData} appContextData - App context data
   *
   * @returns {IClientData} the client data
   */
  public getClientData(appContextData: IAppContextData): Observable<IClientData> {
    const url = `${this.baseOidcApiUrl}/clients`;
    const params = {
      applicationContextId: appContextData.appContextId,
      locale: appContextData.ui_locales,
    };

    return this.http.get<IClientData>(url, { params });
  }

  /**
   * Set Client Data
   *
   * @returns IClientData
   */
  public setClientData(aci: string, locale: string): Observable<IClientData> {
    this.appContextData = {
      appContextId: aci,
      ui_locales: locale,
    };
    return this.getClientData(this.appContextData).pipe(
      map((response: IClientData) => {
        this.clientData = response;
        sessionStorage.setItem('clientData', JSON.stringify(this.clientData));
        return this.clientData;
      })
    );
  }

  /**
   * Get client data
   *
   * @returns {IClientData} the client data
   */
  public get clientData(): IClientData {
    return this._clientData;
  }

  /**
   * Set app context data
   *
   * @param {IClientData} clientData - the app context data
   */
  public set clientData(clientData: IClientData) {
    sessionStorage.setItem('clientData', JSON.stringify(clientData));
    this._clientData = clientData;
  }

  /**
   * Get app context data
   *
   * @returns {IAppContextData} appContextData the app context data
   */
  public get appContextData(): IAppContextData {
    return this._appContextData;
  }

  /**
   * Set app context data
   *
   * @param {IAppContextData} appContextData the app context data
   */
  public set appContextData(appContextData: IAppContextData) {
    this._appContextData = appContextData;

    if (!appContextData.appContextId) {
      this._appContextData.appContextId = this.configService.aci_default;
      this._appContextData.ui_locales = this.configService.locale_default;
    }
  }

  /**
   * Get login challenge
   *
   * @returns {string} - the login challenge
   */
  public get loginChallenge(): string {
    return this._challenge;
  }

  /**
   * Set login challenge
   *
   * @param {string} challenge - the login challenge
   */
  public set loginChallenge(challenge: string) {
    this._challenge = challenge;
  }

  /**
   * Get sign in page url
   *
   * @returns {string} - the sign in url
   */
  public getsignInPageUrl(): string {
    return sessionStorage.getItem('sign-in-page-url');
  }

  /**
   * Set sign in page url
   *
   * @param {string} url - the sign in page url
   */
  public setSignInPageUrl(url: string) {
    sessionStorage.setItem('sign-in-page-url', url);
  }

  /**
   * POST to OIDC Service
   *
   * @param {string} userName - user name
   * @param {string} passWord - password
   * @param {string} challenge - login challenge
   * @param {string} remember - indicator if sign in should be remembered
   *
   * @returns {IRedirectData} the url to redirect to on success
   */
  public login(userName: string, passWord: string, challenge: string, remember: boolean): Observable<IRedirectData> {
    const url = `${this.baseOidcApiUrl}/login`;
    const params = {
      username: userName,
      password: passWord,
      challenge,
      appContextId: this.appContextData.appContextId,
      locale: this.appContextData.ui_locales,
      remember,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': this.appContextData.ui_locales,
      }),
    };

    return this.http.post<IRedirectData>(url, params, httpOptions);
  }

  /**
   * Redirect user to the external URL
   *
   * @param {string} url external URL to redirect to
   */
  public redirect(url: string): void {
    window.location.href = url;
  }

  public forgetUsername(userEmail: string, locale: string, aci: string): Observable<IForgotPasswordData> {
    const url = `${this.baseUsersApiUrl}/users/recoverusername`;
    const params = {
      userEmail,
      aci,
      locale,
      backUrl: this.getsignInPageUrl(),
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };

    return this.http.post<IForgotPasswordData>(url, params, httpOptions);
  }

  public resetPassword(userName: string, locale: string, aci: string): Observable<IForgotPasswordData> {
    const url = `${this.baseUsersApiUrl}/users/forgotpassword`;
    const params = {
      userName,
      aci,
      locale,
      backUrl: this.getsignInPageUrl(),
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };

    return this.http.post<IForgotPasswordData>(url, params, httpOptions);
  }

  public createUsersPassword(token: string, password: string): Observable<ICreatePasswordData> {
    const url = `${this.baseUsersApiUrl}/users/resetpassword`;
    const params = {
      token,
      password,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };

    return this.http.post<ICreatePasswordData>(url, params, httpOptions);
  }

  public changeUsersPassword(accessToken: string, currentPassword: string, newPassword: string): Observable<IChangePasswordData> {
    const url = `${this.baseUsersApiUrl}/users/password/change`;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
    });

    const params = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };
    return this.http.post<IChangePasswordData>(url, params, { headers });
  }

  /**
   * Gets a users account details based on the magic token. The account the token is associated with should not have
   * successfully completed account activation in the past.
   *
   * @param {string} token - the magic activation token
   */
  public getAccountActivationData(token: string): Observable<IAccountActivationData> {
    const url = `${this.baseUsersApiUrl}/users/activateaccount`;
    const params = {};

    if (token) {
      params['token'] = token;
    }

    return this.http.get<IAccountActivationData>(url, { params });
  }

  public setUsersPassword(magictoken: string, password: string): Observable<ISetPasswordData> {
    const url = `${this.baseUsersApiUrl}/users/activateaccount`;
    const params = {
      magictoken,
      password,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };
    return this.http.post<ISetPasswordData>(url, params, httpOptions);
  }

  /**
   * POST to OIDC Service
   *
   * @param {string} email - email
   * @param {string} aci - aci
   *
   * @returns {IRedirectData} the url to redirect to on success
   */
  public ssoLogin(email: string, aci: string): Observable<IRedirectData> {
    const url = `${this.configService.authenticationApiUrl}/wayf/identityProvider`;
    const params = {
      email_Id: email,
      aci,
      back_Url: this.getsignInPageUrl(),
    };
    const options = {
      params: params,
      withCredentials: true,
    };
    return this.http.get<IRedirectData>(url, options);
  }

  /**
   * Get Brand data
   *
   * @param {string} email - email
   * @param {string} aci - aci
   *
   * @returns {IBrandData} the marketing information
   */
  public getBrand(locale: string, aci: string, ipAddress: string): Observable<IBrandData> {
    const url = `${this.configService.usersApiUrl}/brand`;
    const params = {
      locale,
      applicationContextId: aci,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'X-Forwarded-For': ipAddress,
      }),
      params: new HttpParams({ fromObject: params }),
    };

    return this.http.get<IBrandData>(url, httpOptions);
  }

  public getMarketingInfo(): IBrandData {
    return JSON.parse(sessionStorage.getItem('brandData'));
  }

  public getUserProfile(userPermId: string, accessToken: string): Observable<IUserData> {
    const url = `${this.baseUsersApiUrl}/users/userProfile`;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
    });
    return this.http.get<IUserData>(url, { headers });
  }

  public countryCodeListData(): Observable<any> {
    const url = `${this.baseUsersApiUrl}/users/countrycodes`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };
    return this.http.get<any>(url, httpOptions);
  }

  public localeListData(): Observable<any> {
    const url = `${this.baseUsersApiUrl}/users/locales`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };
    return this.http.get<any>(url, httpOptions);
  }

  public timezoneListData(): Observable<any> {
    const url = `${this.baseUsersApiUrl}/users/timezones`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };
    return this.http.get<any>(url, httpOptions);
  }

  public getUserProductSubscriptions(userPermId: string, accessToken: string): Observable<IUserProductSubscriptions> {
    const url = `${this.baseUsersApiUrl}/users/productsubscriptions/${userPermId}`;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
    });
    return this.http.get<IUserProductSubscriptions>(url, { headers });
  }

  public updateProfile(
    userPermId: string,
    accessToken: string,
    firstName: string,
    lastName: string,
    email: string,
    mobilePhone: string,
    userName: string,
    languagePreference: string,
    displayPreference: string,
    timezone: string
  ): Observable<IUpdate> {
    const url = `${this.baseUsersApiUrl}/users/userProfile`;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
    });

    const params = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobilePhone: mobilePhone,
      userName: userName,
      languagePreference: languagePreference,
      displayPreference: displayPreference,
      timezone: timezone,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };
    return this.http.put<IUpdate>(url, params, { headers });
  }

  public lastUsedIdpLogin(challenge: string, aci: string): Observable<ILogin> {
    const params = {
      login_challenge: challenge,
      aci,
    };
    const url = `${this.configService.authenticationApiUrl}/wayf/identityProvider/samlResponse`;
    return this.http.get<ILogin>(url, { params, withCredentials: true });
  }

  public getAccessToken(username: string, password: string, magicToken: string): Observable<AccessTokenInfo> {
    const url = `${this.baseOidcApiUrl}/login/getaccesstoken`;
    const params = {
      username: username,
      password: password,
      magicToken: magicToken,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };

    return this.http.post<AccessTokenInfo>(url, params, httpOptions);
  }

  public getAuthMarketingDetails(aci: string, locale: string): Observable<IAuthMarketingDetails[]> {
    console.log('Inside getAuthMarketingDetails from auth.service.ts');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };
    return this.http.get<IAuthMarketingDetails[]>(
      `${this.configService.oidcApiUrl}/brand?applicationContextId=${aci}&locale=${locale}`,
      httpOptions
    );
  }

  public getAbeFlagDetails(flagName: string): Observable<ABEResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };
    return this.http.get<ABEResponse>(`${this.configService.oidcApiUrl}/login/abeFlag/${flagName}`, httpOptions);
  }

  public getAciExcludeFlagDetails(flagName: string, aci: string): Observable<ABEResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };
    return this.http.get<ABEResponse>(`${this.configService.oidcApiUrl}/login/aciExcludeFlag/${flagName}?aci=${aci}`, httpOptions);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): UrlTree | Observable<boolean | UrlTree> | boolean {

    // check mandatory params
    if (!this.validateQueryParams(route.queryParams)) {
      this.redirectToErrorPage();
    }

    // Ideally we would like the calling app to handle it before requesting the login page
    return this.validateLoginChallenge(route.queryParams.login_challenge);
  }

  /**
   * Check if mandatory query parameters are available
   *
   * @param {Params} params Query params
   *
   * @returns {boolean} indicator if mandatory query params are available
   */
  validateQueryParams(params: Params): boolean {
    const requiredParams = ['login_challenge'];
    return requiredParams.every((param) => (params[param] ? true : false));
  }

  /**
   * Validate login challenge.
   *
   * @param {string} challenge Login challenge
   *
   * @returns {boolean} indicator if route can be activated
   */
  validateLoginChallenge(challenge: string):  Observable<boolean | UrlTree> {
    return this.authService.validateLoginChallenge(challenge).pipe(
      catchError((err) => of(this.redirectToErrorPage(err.error.errorCode)))
    );
  }

  private redirectToErrorPage(code?: string): UrlTree {
    return this.router.parseUrl(`/error?code=${code}`);
  }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from 'ln-templates-angular';

import * as translations from '../../shared/dictionary';
import { AuthDataService, AuthService } from '../../core/services';

@Component({
  selector: 'wam-password-created-success.component',
  templateUrl: './password-created-success.component.html',
  styleUrls: ['./password-created-success.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordCreatedSuccessComponent implements OnInit, OnDestroy {
  isXLargeScreen$: Observable<boolean>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(
    private _observer: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private authDataService: AuthDataService,
    private authService: AuthService) {
    this.translations = translations;
    this.setTitle();
    this.setMetaTag();
    this.setTitleBlock();
  }

  ngOnInit(): void {
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
  }

  setTitle(): void {
    const productName = 'Lexis®';
    const pageTitleSuffix = 'Password Created | LexisNexis';
    const title = `${productName} - ${pageTitleSuffix}`;
    this.titleService.setTitle(title);
  }

  setMetaTag(): void {
    this.metaService.addTag(
      {
        name: 'description',
        content: this.translations.Login_Description_Meta_Tag,
      });
  }

  setTitleBlock(): void {
    this.authDataService.setTitle(this.translations.Password_Created_Successfull_Message);
    this.authDataService.setDescription('');
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=description');
  }

  back(): void {
    const backUrl = this.authService.getsignInPageUrl();

    if (backUrl) {
      this.authService.redirect(backUrl);
    }
  }
}

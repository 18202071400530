import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from 'ln-templates-angular';

import * as translations from '../../shared/dictionary';
import { AuthDataService, AuthService } from '../../core/services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'wam-password-chnage-success.component',
  templateUrl: './password-change-success.component.html',
  styleUrls: ['./password-change-success.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordChangeSuccessComponent implements OnInit, OnDestroy {
  isXLargeScreen$: Observable<boolean>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;
  locale: string;
  aci: string;

  constructor(
    private _observer: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private authDataService: AuthDataService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    this.translations = translations;
    this.setTitle();
    this.setMetaTag();
    this.setTitleBlock();
  }

  ngOnInit(): void {
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );

    this.route.queryParams.subscribe((params) => {
      this.locale = params['locale'];
      this.aci = params['aci'];
    });
  }

  setTitle(): void {
    const productName = 'Lexis®';
    const pageTitleSuffix = 'Password Created | LexisNexis';
    const title = `${productName} - ${pageTitleSuffix}`;
    this.titleService.setTitle(title);
  }

  setMetaTag(): void {
    this.metaService.addTag(
      {
        name: 'description',
        content: this.translations.Login_Description_Meta_Tag,
      });
  }

  setTitleBlock(): void {
    this.authDataService.setTitle(this.translations.Auth_ChangePassword_Success);
    this.authDataService.setDescription('');
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=description');
  }

  back(): void {
    this.router.navigate(
      ['userprofile/update'],
      { queryParams: { aci: this.aci, locale: this.locale } }
    );
  }
}

import { NgModule } from '@angular/core';

import { ButtonModule, CheckboxModule } from 'ln-components-angular';
import { AuthLayoutModule, AuthFooterModule } from 'ln-templates-angular';

@NgModule({
  exports: [
    ButtonModule,
    CheckboxModule,
    AuthLayoutModule,
    AuthFooterModule,
  ],
})
export class GlobalComponentModule { }

/** WAM_Auth_UI_Default_Error_Msg_401 */
export const DefaultErrorMsg401 = 'auth.ui.defaultErrorMsg401';

/** WAM_Auth_UI_Default_Error_Msg_401-1 */
export const DefaultErrorMsg401_1 = 'auth.ui.defaultErrorMsg401_1';

/** WAM_Auth_UI_Default_Error_Msg_403-1 */
export const DefaultErrorMsg403_1 = 'auth.ui.defaultErrorMsg403_1';

/** WAM_Auth_UI_Default_Error_Msg_403-3 */
export const DefaultErrorMsg403_3 = 'auth.ui.defaultErrorMsg403_3';

/** WAM_Auth_UI_Default_Error_Msg_403-4 */
export const DefaultErrorMsg403_4 = 'auth.ui.defaultErrorMsg403_4';

/** WAM_Auth_UI_Default_Error_Msg_403-5 */
export const DefaultErrorMsg403_5 = 'auth.ui.defaultErrorMsg403_5';

/** WAM_Auth_UI_Default_Error_Msg_403-6 */
export const DefaultErrorMsg403_6 = 'auth.ui.defaultErrorMsg403_6';

/** WAM_Auth_UI_Default_Error_Msg_403-7 */
// eslint-disable-next-line
export const DefaultErrorMsg403_7 = 'auth.ui.defaultErrorMsg403_7';

/** WAM_Auth_UI_Default_Error_Msg_403-8 */
export const DefaultErrorMsg403_8 = 'auth.ui.defaultErrorMsg403_8';

/** WAM_Auth_UI_Default_Error_Msg_403-9 */
export const DefaultErrorMsg403_9 = 'auth.ui.defaultErrorMsg403_8';

/** WAM_Auth_UI_Default_Error_Msg_403-10 */
export const DefaultErrorMsg403_10 = 'auth.ui.defaultErrorMsg403_10';

/** WAM_Auth_UI_Default_Error_Msg_403-11 */
export const DefaultErrorMsg403_11 = 'auth.ui.defaultErrorMsg403_11';

/** WAM_Auth_UI_Default_Error_Msg_403-12 */
export const DefaultErrorMsg403_12 = 'auth.ui.defaultErrorMsg403_12';

/** WAM_Auth_UI_Cookies_Text */
export const Cookies_Text = 'auth.ui.footer.cookies';

/** WAM_Auth_UI_Privacy_Link */
export const Privacy_Link = 'https://www.lexisnexis.com/en-us/terms/privacy-policy.page';

/** WAM_Auth_UI_Privacy_Text */
export const Privacy_Text = 'auth.ui.footer.privacyText';

/** WAM_Auth_UI_Terms_Conditions_Link */
export const Terms_Conditions_Link = 'https://www.lexisnexis.com/terms/general.aspx';

/** WAM_Auth_UI_Terms_Conditions_Text */
export const Terms_Conditions_Text = 'auth.ui.footer.termsConditionsText';

/** WAM_Auth_UI_Terms_CopyRight_Link */
export const CopyRight_Link = 'https://www.lexisnexis.com/terms/copyright.aspx';

/** WAM_Auth_UI_Terms_CopyRight_Text */
export const CopyRight_Text = 'LexisNexis';

/** WAM_Auth_UI_Terms_Logo_Link */
export const Logo_Link = 'https://www.relx.com/';

/** WAM_Auth_UI_Terms_LNLogo_Link */
export const LNlogo_Link = 'https://www.lexisnexis.com/';

/** WAM_Auth_UI_Terms_CopyRight_Text */
export const AboutUs_Text = 'auth.ui.footer.aboutUsText';

/** WAM_Auth_UI_About_Link */
export const About_Link = 'https://www.lexisnexis.com/en-us/about-us/about-us.page';

/** WAM_Auth_UI_Help_Link */
export const Help_Link = 'https://lexisnexis.custhelp.com/app/answers/answer_view/a_id/1089006/loc/en_US/s/16777221_109661e4ad2bf1-2e09-47ba-b5e4-c7ead7166df6/prTxnId/1624445726/txnId/1624445726#__highlight';

/** WAM_Auth_UI_Help_Text */
export const Help_Text = 'auth.help';

/** WAM_Auth_UI_ErrorNote_Default */
export const ErrorNote_Default = 'auth.error.defaultNote';

/** WAM_Auth_UI_ErrorNote_500 */
export const ErrorNote_500 = 'auth.error.defaultNote.500';

/** WAM_Auth_UI_ErrorNote_403 */
export const ErrorNote_403 = 'auth.error.defaultNote.403';

/** WAM_Auth_UI_ErrorNote_455 */
export const ErrorNote_476 = 'auth.error.defaultNote.476';

/** WAM_Auth_UI_ErrorDescription_455 */
export const ErrorDescription_476 = 'auth.error.defaultDescription.476';

/** WAM_Auth_UI_ErrorDescription_455 */
export const ErrorSuggestion_476 = 'auth.error.defaultSuggestion.476';

/** WAM_Auth_UI_ErrorNote_455 */
export const ErrorNote_468 = 'auth.error.defaultNote.468';

/** WAM_Auth_UI_ErrorDescription_455 */
export const ErrorDescription_468 = 'auth.error.defaultDescription.468';

/** WAM_Auth_UI_ErrorDescription_455 */
export const ErrorSuggestion_468 = 'auth.error.defaultSuggestion.468';

/** WAM_Auth_UI_ErrorDescription_Default */
export const ErrorDescription_Default = 'auth.error.defaultDescription';

/** WAM_Auth_UI_ErrorDescription_500 */
export const ErrorDescription_500 = 'auth.error.defaultDescription.500';

/** WAM_Auth_UI_ErrorDescription_403 */
export const ErrorDescription_403 = 'auth.error.defaultDescription.403';

/** WAM_Auth_UI_ErrorSuggestion_Default */
export const ErrorSuggestion_Default = 'auth.error.defaultSuggestion';

/** WAM_Auth_UI_ErrorSuggestion_500 */
export const ErrorSuggestion_500 = 'auth.error.defaultSuggestion.500';

/** WAM_Auth_UI_ErrorSuggestion_403 */
export const ErrorSuggestion_403 = 'auth.error.defaultSuggestion.403';

/** WAM_Auth_UI_CustomerCareNumber */
export const CustomerCareNumber = '1-800-543-6862';

/** WAM_Auth_UI_SignUp_NoAccount */
export const SignUp_NoAccount = 'auth.signUp.noAccount';

/** WAM_Auth_UI_SignUp_Free */
export const SignUp_Free = 'auth.signUp.free';

/** WAM_Auth_UI_Marketing_Header */
export const Marketing_Header = 'auth.marketing.header';

/** WAM_Auth_UI_Marketing_Desc */
export const Marketing_Desc = 'auth.marketing.description';

/** WAM_Auth_UI_Marketing_Explore */
export const Marketing_Explore = 'auth.marketing.explore';

/** WAM_Auth_UI_Branding_Logo */
export const Branding_Logo = 'Lexis<sup>&reg;</sup>';

/** WAM_Auth_UI_LoginForm_EmptyPassword */
export const LoginForm_EmptyPassword = 'auth.ui.loginFormErrorEmptyPassword';

/** WAM_Auth_UI_LoginForm_emptyUserName */
export const LoginForm_emptyUserName = 'auth.ui.loginFormErrorEmptyUserName';

/** WAM_Auth_UI_LoginForm_username */
export const LoginForm_username = 'auth.loginForm.username';

/** WAM_Auth_UI_LoginForm_password */
export const LoginForm_password = 'auth.loginForm.password';

/** WAM_Auth_UI_LoginForm_show */
export const LoginForm_show = 'auth.loginForm.show';

/** WAM_Auth_UI_LoginForm_hide */
export const LoginForm_hide = 'auth.loginForm.hide';

/** WAM_Auth_UI_LoginForm_KeepMeLoggedIn */
export const LoginForm_KeepMeLoggedIn = 'auth.loginForm.keepLoggedIn';

/** WAM_Auth_UI_LoginForm_ForgotUserName */
export const LoginForm_ForgotUserName = 'auth.loginForm.forgotUsername';

/** WAM_Auth_UI_LoginForm_ForgotPassword */
export const LoginForm_ForgotPassword = 'auth.loginForm.forgotPassword';

/** WAM_Auth_UI_LoginForm_SignIn */
export const LoginForm_SignIn = 'auth.loginForm.signIn';

/** WAM_Auth_UI_LoginForm_PasswordShown */
export const LoginForm_PasswordShown = 'Password shown';

/** WAM_Auth_UI_LoginForm_PasswordHidden */
export const LoginForm_PasswordHidden = 'Password hidden';

/** WAM_Auth_UI_Header_CustomerSupport */
export const Header_CustomerSupport = 'auth.error.customerSupportHeader';

/** WAM_Auth_UI_Error_BackSignIn */
export const Error_BackSignIn = 'auth.error.backToSignIn';

/** WAM_Auth_UI_Login_SignIn */
export const Login_SignIn = 'auth.loginPage.heading';

/** WAM_Auth_UI_Login_Desc */
export const Login_Desc = 'auth.loginPage.subHeading';

/** WAM_Auth_UI_Login_Description_Meta_Tag  */
export const Login_Description_Meta_Tag
= 'LexisNexis users sign in here. Click here to login and begin conducting your legal research now.';

/** WAM_Auth_UI_Reset_Password_Header */
export const Reset_Password_Header = 'auth.restPassword.header';

/** WAM_Auth_UI_Reset_Password_Desc */
export const Reset_Password_Desc = 'auth.resetPassword.description';

/** WAM_Auth_UI_Reset_Password_Message */
export const Reset_Password_Message = 'auth.resetPassword.message';

/** WAM_Auth_UI_Reset_Password_Submit */
export const Reset_Password_Submit = 'auth.resetPassword';

/** WAM_Auth_UI_Create_Password_Heading  */
export const Create_Password_Heading = 'auth.createPassword.heading';

/** WAM_Auth_UI_Create_Password_SubHeading  */
export const Create_Password_SubHeading = 'auth.createPassword.subHeading';

/** WAM_Auth_UI_Create_Password_Submit  */
export const Create_Password_Submit = 'auth.resetPassword';

/** WAM_Auth_UI_Button_Text_Back  */
export const Button_Text_Back = 'auth.back';

/** WAM_Auth_UI_Create_Password_Input  */
export const Create_Password_Input = 'auth.password';

/** WAM_Auth_UI_Create_Password_Input_Confirm  */
export const Create_Password_Input_Confirm = 'auth.confirmPassword';

/** WAM_Auth_UI_Reset_Password_InValid_UserName */
export const Reset_Password_InValid_UserName = 'auth.ui.resetPasswordInvalidUserName';

/** WAM_Auth_UI_Reset_Password_UserName_Required */
export const Reset_Password_UserName_Required = 'auth.ui.resetPassword.errorEmptyUserName';

/** WAM_Auth_UI_Password_Created_Successfull_Message */
export const Password_Created_Successfull_Message = 'auth.resetPassword.success';

/** WAM_Auth_UI_Reset_Password_Account_Restricted */
export const Error_OTP_Count_Exceeded = "You have exceeded the limit. Please contact customer support at ";

/** WAM_Auth_UI_OTP_Count_Account_locked  */
export const Error_OTP_Count_Account_Locked = "auth.ui.defaultErrorMsg468";

/** WAM_Auth_UI_Reset_Password_Strenthening Messages Specifically used for reset password messages
 * The new line character is for showing the error message in new line on toll tip */
export const Pwd_MustContain8to50_Char='auth.ui.password.strengthening.1';
export const Pwd_Should_Not_Contain_Space='auth.ui.password.strengthening.2';
export const Pwd_Should_Contain_UpperCase='auth.ui.password.strengthening.3';
export const Pwd_Should_Contain_LowerCase='auth.ui.password.strengthening.4';
export const Pwd_Should_Contain_Number='auth.ui.password.strengthening.5';
export const Pwd_Special_Char='auth.ui.password.strengthening.6';
export const Pwd_Atleast_one_Char='auth.ui.password.strengthening.7';

export const Pwd_Current_Mismatch_Error='auth.ui.current.password.match.error';
export const Pwd_Match_Error='auth.ui.password.match.error';
export const Pwd_Confirm_Your_Password="Confirm your password\n";
export const Pwd_MustContain_Atleast_8to50_Char="auth.ui.password.strengthening.1"

/** WAM_Auth_UI_Reset_Password_UserName_Required */
export const Password_Required = 'auth.ui.password.required.error';
export const Password_strength_rules_not_matched = 'auth.ui.password.rules.unmatched.error';
export const Password_contains_username =  'auth.ui.password.username.found.error';
export const Password_contains_reverse_username = 'auth.ui.password.reverse.username.error';
export const Password_should_not_same_as_last_five_passwords = 'auth.ui.password.last.five.passwords.error';
export const Password_policy_violation = 'auth.ui.password.policy.error';
export const Password_empty =  'auth.ui.password.empty.error';
export const Password_requirements_not_met = 'auth.ui.password.requirement.error';
export const Password_set_link_expired = 'auth.ui.password.link.expired.error';

/** WAM_Auth_UI_Activate_Account  */
export const Activate_Account_Heading = 'Activate Your Account';
export const Activate_Account_Heading_Set_Pw = 'Set New Password';
export const Activate_Account_Tab = 'Activate account';
export const Activate_Account_Tab_Set_Pw = 'Set password';
export const Activate_Account_Desc = 'Welcome';
export const Activate_Account_Button = 'Activate';
export const Activate_Account_Button_Set_Pw = 'Set Password';
export const Activate_Account_Button_Go_To_Product = 'Go To Product';
export const Activate_Account_Password = 'New Password';
export const Activated_Account_Success_Set_Pw = 'Password is set successfully'
export const Activated_Account_Success = 'Your account is ready';

/** WAM_Auth_UI_LoginForm_emptyEmail */
export const LoginForm_email = 'auth.loginForm.email';
export const LoginForm_withoutSSO = 'auth.loginForm.withoutSSO';
export const LoginForm_withSSO = 'auth.loginForm.withSSO';
export const LoginForm_SSO_Error = 'auth.ui.loginFormSSOError';
export const LoginForm_SSO_456 = 'auth.ui.loginForSS0.456';
export const LoginForm_emptyEmail = 'auth.ui.loginFormErrorEmptyEmail';

/** WAM_Auth_UI_Login_SSO_SignIn */
export const Login_SSO_SignIn = 'auth.loginSSOPage.heading';

/** WAM_Auth_UI_Login_SSO_Desc */
export const Login_SSO_Desc = 'auth.loginSSOPage.subHeading';
export const Login_SSO_Error = 'auth.ui.SSO.error';

export const Auth_OR = 'auth.OR';
export const Auth_ConfirmNewPassword = 'auth.confirmNewPassword';
export const Auth_NewPassword = 'auth.newPassword';
export const Auth_CurrentPassword = 'auth.currentPassword';
export const Auth_ChangePassword = 'auth.changePassword';


export const Auth_ChangePassword_Success = 'auth.changePassword.success';
export const Auth_ChangePAssword_BackToSignIn = 'auth.changePassword.backToSignIn';

export const Auth_UserProfile_Success = 'auth.userprofile.success';
export const Auth_Back_Product = 'auth.back.product';

/** WAM_Auth_UI_Create_Password_Heading  */
export const Change_Password_Heading = 'auth.changePassword.heading';

/** WAM_Auth_UI_Create_Password_SubHeading  */
export const Change_Password_SubHeading = 'auth.changePassword.subHeading';

/** WAM_Auth_UI_Recover_Password_Header */
export const Recover_Password_Header = 'auth.recoverPassword.header';

/** WAM_Auth_UI_Reset_Password_Desc */
export const Recover_Password_Desc = 'auth.recoverPassword.description';

/** WAM_Auth_UI_Reset_Password_Message */
export const Recover_Password_Message = 'auth.recoverPassword.message';

/** WAM_Auth_UI_Reset_Password_Submit */
export const Recover_Password_Submit = 'auth.recoverPassword';

/** WAM_Auth_UI_Reset_Password_Submit */
export const Recover_Username_Invalid_Email = 'auth.recoverUsername.InvalidEmail';

/** WAM_Auth_UI_Support_Link */
export const Support_Link = 'https://supportcenter.lexisnexis.com/app/answers/answer_view/a_id/1089006';

/** WAM_Auth_UI_Support_Text */
export const Support_Text = 'auth.support';

/** WAM_Auth_UI_Create_Password_Submit  */
export const Self_Registration_Button_Text = 'auth.selfRegistration.button.text';

export const contact_link = 'https://www.lexisnexis.fr/contact-us';
export const LoginForm_Contact_Text = 'auth.loginForm.contact';
export const LoginForm_Contact_Number = 'auth.loginForm.contact_number';
export const LoginForm_Conditions_Text = 'auth.loginForm.conditions_text';
export const LoginForm_CreateAccount = 'auth.loginForm.createAccount';

/** WAM_Auth_UI_LoginForm_Next */
export const LoginForm_Next = 'auth.loginForm.next';




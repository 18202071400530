import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { OAuthModule } from 'angular-oauth2-oidc';

import { LogoutFormComponent } from './logout-form.component';

@NgModule({
  declarations: [LogoutFormComponent],
  imports: [SharedModule, OAuthModule.forRoot(),],
  exports: [LogoutFormComponent],
})
export class LogoutFormModule { }

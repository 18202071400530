import { Component, ViewEncapsulation } from '@angular/core';
import * as translations from '../../shared/dictionary';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'wam-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
  year: number;
  cookiesUrl: string;
  aboutUsUrl = translations.About_Link;
  privacyUrl = translations.Privacy_Link;
  logoLink = translations.Logo_Link;
  copyRightLink = translations.CopyRight_Link;
  termsURL = translations.Terms_Conditions_Link;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(private authService: AuthService) {
    this.translations = translations;
    this.year = new Date().getFullYear();
    const data = JSON.parse(sessionStorage.getItem('clientData'));
    if (!this.authService.clientData && data) {
      this.authService.clientData = data;
    }
    if (this.authService.clientData) {
      this.setClientData();
    }
  }

  private setClientData() {
    if (this.authService.clientData.privacyURL) {
      this.privacyUrl = this.authService.clientData.privacyURL;
    }

    if (this.authService.clientData.copyrightURL) {
      this.copyRightLink = this.authService.clientData.copyrightURL;
    }

    if (this.authService.clientData.termsURL) {
      this.termsURL = this.authService.clientData.termsURL;
    }

    if (this.authService.clientData.aboutUsURL) {
      this.aboutUsUrl = this.authService.clientData.aboutUsURL;
    }
  }
}

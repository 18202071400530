import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { SignInProfileSuccessComponent } from './signin-profile-success.component';

@NgModule({
  declarations: [SignInProfileSuccessComponent],
  imports: [SharedModule],
  exports: [SignInProfileSuccessComponent],
})
export class SignInProfileSuccessModule { }

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from 'ln-templates-angular';

import { OAuthService } from 'angular-oauth2-oidc';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as translations from '../../shared/dictionary';
import { AuthService, ErrorService, AuthDataService, AppConfigService } from '../../core/services';
import {ICountryCodeData, IErrorData, IUpdate, IUserData, ILocale, ITimezone} from '../../core/models/auth';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";


@Component({
  selector: 'wam-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class UserProfileComponent implements OnInit, OnDestroy {
  isXLargeScreen$: Observable<boolean>;
  username: string;
  inlineErrorDisplay: string;
  locale: string;
  aci: string;
  customerCareNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  userName: string;
  loginChecked = false;
  submitted = true;
  UpdateForm: FormGroup;
  values: any;
  countryCodeOptions = [];
  localeOptions = [];
  timezoneOptions = [];
  countryCodeData : ICountryCodeData[];
  localeData: ILocale[];
  timezoneData: ITimezone[];
  result1: any;
  countryCodeList : any;
  mobileWithCode : string;
  countryCode :string;
  errorShow : boolean;
  languagePreference: string;
  displayPreference: string;
  timezone: string;
  preferenceManagerLink: string;


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(
    private _observer: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private authService: AuthService,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private location: Location,
    private authDataService: AuthDataService,
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private oauthService: OAuthService) {
    this.oauthService.loadDiscoveryDocumentAndLogin().then(() => {
      if (this.oauthService.hasValidAccessToken()  && this.oauthService.hasValidIdToken()) {
        this.loginChecked = true;
      }
      this.getUserDetails();
    });
    this.translations = translations;
    this.setTitle();
    this.setMetaTag();
    this.setTitleBlock();
  }

  ngOnInit(): void {
    this.setCustomerCareNumber();
    this.getCountryCodeList();
    this.getLocaleList();
    this.getTimezoneList();
    this.submitted = false;
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );

    this.route.queryParams.subscribe((params) => {
      this.locale = params['locale'];
      this.aci = params['aci'];
    });
    this.createForm();
  }

  createForm(): void {
    this.UpdateForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$") ]],
      areaCode:[''],
      mobile: [''],
      userName: ['', Validators.required],
      languagePreference: [''],
      displayPreference: [''],
      timezone: ['']
    })
  }
  get firstNameControl(): FormControl {
    return this.UpdateForm.get('firstName') as FormControl;
  }
  get lastNameControl(): FormControl {
    return this.UpdateForm.get('lastName') as FormControl;
  }
  get emailControl(): FormControl {
    return this.UpdateForm.get('email') as FormControl;
  }
  get mobileControl(): FormControl {
    return this.UpdateForm.get('mobile') as FormControl;
  }
  get areaCodeControl(): FormControl {
    return this.UpdateForm.get('areaCode') as FormControl;
  }
  get userNameControl(): FormControl {
    return this.UpdateForm.get('userName') as FormControl;
  }

  setTitle(): void {
    const productName = 'Lexis®';
    const pageTitleSuffix = 'Update Sign-in Profile | LexisNexis';
    const title = `${productName} - ${pageTitleSuffix}`;
    this.titleService.setTitle(title);
  }

  setMetaTag(): void {
    this.metaService.addTag(
      {
        name: 'description',
        content: this.translations.Login_Description_Meta_Tag,
      });
  }

  navigateToLandingPage() {
    this.router.navigate(['/password/change'],{
      queryParams: {
        aci: this.aci,
        locale: this.locale
      }
    });
  }

  resetPasword() {
    this.inlineErrorDisplay = '';
    if (this.oauthService.hasValidAccessToken()) {
      const access = this.oauthService.getAccessToken();
      const userPermId = this.getUserPermId();
      this.authService.updateProfile(userPermId, access, this.UpdateForm.value.firstName,this.UpdateForm.value.lastName, this.UpdateForm.value.email,
        this.UpdateForm.value.areaCode+' '+this.UpdateForm.value.mobile , this.UpdateForm.value.userName,
        this.UpdateForm.value.languagePreference, this.UpdateForm.value.displayPreference, this.UpdateForm.value.timezone)
        .subscribe((response: IUpdate) => {
          this.router.navigate(['/userprofile/success'],
            { queryParams: { aci: this.aci, locale: this.locale } }
            );
          console.log('Details updated successfully');
          this.submitted = true;
        }, (error: IErrorData) => {
          console.log('Error Updating Profile Details');
          this.submitted = false;
          this.errorShow = true;
          if (error && error.error && error.error.errorCode) {
            const code: string = error.error.errorCode;
            if (code === '480') {
              this.inlineErrorDisplay = "First Name cannot be null";
            } else if (code === '481') {
              this.inlineErrorDisplay = "Last Name cannot be null";
            } else if (code === '482') {
              this.inlineErrorDisplay = "Email Address cannot be null";
            } else if (code === '483') {
              this.inlineErrorDisplay = "Location ID cannot be null";
            } else if (code === '484') {
              this.inlineErrorDisplay = "First Name, Last Name and Email Address combination already exists. Please contact your administrator.";
            } else if (code === '427') {
              this.inlineErrorDisplay = "Location ID is invalid.";
            }else if (code === '486') {
              this.inlineErrorDisplay = "Username already exists. Please choose another one.";
            }else if (code === '400') {
              this.inlineErrorDisplay = "No changes made to the profile.";
            } else {
              this.UpdateForm.markAllAsTouched();
            }
          }
        });
    }}

  setTitleBlock(): void {
    this.authDataService.setTitle('auth.userprofile.header');
    this.authDataService.setDescription('auth.updateprofile.description');
  }

  back() {
    window.history.back();
  }
  change() {
    this.submitted = true;
    this.errorShow = false;
  }

  getUserDetails() {
    if (this.oauthService.hasValidAccessToken()) {
      const access = this.oauthService.getAccessToken();
      const userPermId = this.getUserPermId();
      this.authService.getUserProfile(userPermId, access)
        .subscribe((response: IUserData) => {
          this.firstName = response.firstName;
          this.UpdateForm.controls.firstName.setValue(this.firstName);
          this.lastName = response.lastName;
          this.UpdateForm.controls.lastName.setValue(this.lastName);
          this.email = response.email;
          this.UpdateForm.controls.email.setValue(this.email);
          this.mobileWithCode = response.mobilePhoneNumber;
          if(this.mobileWithCode != null) {
            this.countryCode = this.mobileWithCode.split(' ')[0];
            if(this.countryCode !=null){
              this.UpdateForm.controls.areaCode.setValue(this.countryCode);
              this.mobile = this.mobileWithCode.split(' ')[1];
              this.UpdateForm.controls.mobile.setValue(this.mobile);
            }else{
              this.UpdateForm.controls.mobile.setValue(this.mobileWithCode);
            }
          }
          this.userName = response.userName;
          this.UpdateForm.controls.userName.setValue(this.userName);
          
          this.languagePreference = response.languagePreference;
          this.UpdateForm.controls.languagePreference.setValue(this.languagePreference);

          this.displayPreference = response.displayPreference;
          this.UpdateForm.controls.displayPreference.setValue(this.displayPreference);

          this.timezone = response.timezone;
          this.UpdateForm.controls.timezone.setValue(this.timezone);

          this.preferenceManagerLink = response.preferenceManagerLink;
        });
    }
  }
  public getUserPermId(): string {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    const sub = 'sub';
    return claims[sub];
  }

  getCountryCodeList() {
    this.authService.countryCodeListData()
      .subscribe((response: any) => {
        this.result1 = JSON.stringify(response);
        this.values = JSON.parse(this.result1);
        this.countryCodeData = this.values.countryCodeList;
        this.countryCodeData.forEach(element => {
          this.countryCodeOptions.push({
            value: `${element.countryCode}`,
            name: `${element.countryAbbreviation}`
          });
        });
      }, (err: any) => {
        console.log("errors");
      });
  }

  getLocaleList() {
    this.authService.localeListData()
      .subscribe((response: any) => {
        this.result1 = JSON.stringify(response);
        this.values = JSON.parse(this.result1);
        this.localeData = this.values.localeList;
        this.localeData.forEach(element => {
          this.localeOptions.push({
            value: `${element.locale}`,
            name: `${element.displayValue}`
          });
        });
      }, (err: any) => {
        console.log("errors");
      });
  }

  getTimezoneList() {
    this.authService.timezoneListData()
      .subscribe((response: any) => {
        this.result1 = JSON.stringify(response);
        this.values = JSON.parse(this.result1);
        this.timezoneData = this.values.timezoneList;
        this.timezoneData.forEach(element => {
          this.timezoneOptions.push({
            value: `${element.timezone}`,
            name: `${element.displayValue}`
          });
        });
      }, (err: any) => {
        console.log("errors");
      });
  }


  ngOnDestroy(): void {
    this.metaService.removeTag('name=description');
  }


  private setCustomerCareNumber() {
    if (this.authService.clientData && this.authService.clientData.csPhoneNumber) {
      this.customerCareNumber = this.authService.clientData.csPhoneNumber;
    }
  }
}

import { AuthConfig } from 'angular-oauth2-oidc';
import { AppConfigService } from './app-config.service';

export const authChangePassConfigFactory = (configService: AppConfigService): AuthConfig => ({
  issuer: configService.issuer,
  redirectUri: `${window.location.origin  }/password/change`,
  clientId: configService.clientId,
  scope: configService.scope,
  responseType: configService.responseType,
  showDebugInformation: configService.showDebugInformation,
  requireHttps: configService.requireHttps
});

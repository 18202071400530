import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountActivatePageComponent } from './activate-account-page.component';
import { ActivateAccountComponent } from '../../components/activate-account/activate-account.component';

const routes: Routes = [{
  path: 'account',
  component: AccountActivatePageComponent,
  children: [
    {
      path: 'activate',
      component: ActivateAccountComponent,
    }
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountActivatePageRoutingModule { }

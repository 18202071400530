import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { ChangePasswordComponent } from './change-password.component';

@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [SharedModule],
  exports: [ChangePasswordComponent],
})
export class ChangePasswordModule { }

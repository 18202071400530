import { NgModule } from '@angular/core';

import { OAuthModule } from 'angular-oauth2-oidc';
import { SharedModule } from '../../shared/shared.module';
import { ProductSelectionComponent } from './product-selection.component';

@NgModule({
  declarations: [ProductSelectionComponent],
  imports: [
    SharedModule,
    OAuthModule.forRoot(),
  ],
  exports: [ProductSelectionComponent],
})
export class ProductSelectionModule { }

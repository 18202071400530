import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { ResetPasswordComponent } from './reset-password.component';

@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [SharedModule],
  exports: [ResetPasswordComponent],
})
export class ResetPasswordModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GlobalComponentModule } from './global-component.module';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GlobalComponentModule,
    TranslateModule
  ],
})
export class SharedModule { }

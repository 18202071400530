import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IAppConfig } from '../models/app-config';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private http: HttpClient;

  /**
   * App config
   */
  private appConfig: IAppConfig;

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(this.handler);
  }

  /**
   * Get oidc API URL
   *
   * @returns {string} oidc API Url
   */
  get oidcApiUrl(): string {
    return this.appConfig.oidcApiUrl;
  }

  /**
   * Get users API URL
   *
   * @returns {string} Users API Url
   */
  get usersApiUrl(): string {
    return this.appConfig.usersApiUrl;
  }

  /**
   * Get sso API URL
   *
   * @returns {string} sso API Url
   */
  get authenticationApiUrl(): string {
    return this.appConfig.authenticationUrl;
  }

  /**
   * Get forgot password URL
   *
   * @returns {string} forgot password Url
   */
  get forgotPasswordUrl(): string {
    return this.appConfig.forgotPasswordUrl;
  }

  /**
   * Get default aci
   *
   * @returns {string} the default aci value
   */
  get aci_default(): string {
    return this.appConfig.aci_default;
  }

  get locale_default(): string {
    return this.appConfig.locale_default;
  }

  get issuer(): string {
    return this.appConfig.issuer;
  }

  get clientId(): string {
    return this.appConfig.clientId;
  }

  get requireHttps(): boolean {
    return this.appConfig.requireHttps;
  }

  get scope(): string {
    return this.appConfig.scope;
  }

  get responseType(): string {
    return this.appConfig.responseType;
  }

  get showDebugInformation(): boolean {
    return this.appConfig.showDebugInformation;
  }

  /**
   * Get explore URL
   *
   * @returns {string} The explore Url
   */
  get exploreUrl(): string {
    return this.appConfig.exploreUrl;
  }

  /**
   * Get signup url URL
   *
   * @returns {string} The signup Url
   */
  get signupUrl(): string {
    return this.appConfig.signupUrl;
  }

  /**
   * Get singout Url
   *
   * @returns {string} the singout url
   */
  get signoutUrl(): string {
    return this.appConfig.signoutUrl;
  }

  get signinUrl(): string {
    return this.appConfig.signinUrl;
  }

  get progressiveLoginABEEvaluationName(): string {
    return this.appConfig.progressiveLoginABEEvaluationName;
  }

  get progressiveLoginACIExcludeEvaluationName(): string {
    if (this.appConfig && 'progressiveLoginACIExcludeFlagName' in this.appConfig) {
      return this.appConfig.progressiveLoginACIExcludeFlagName;
    } else {
      throw new Error('AppConfig is not loaded or progressiveLoginACIExcludeFlagName is not present in AppConfig');
    }
  }

  /**
   * Load app config
   *
   * @returns
   */
  // eslint-disable-next-line
  public async loadAppConfig(): Promise<any> {
    const data = await this.http.get('/assets/appconfig/appconfig.json').toPromise();
    this.appConfig = data as IAppConfig;
    return data;
  }
}

import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { CreatePasswordComponent } from './create-password.component';

@NgModule({
  declarations: [CreatePasswordComponent],
  imports: [SharedModule],
  exports: [CreatePasswordComponent],
})
export class CreatePasswordModule { }

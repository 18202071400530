import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AuthDataService {
  /**
   * Emits page title
   */
  private _title = new BehaviorSubject<string>('');

  /**
   * Emits page description
   */
  private _description = new BehaviorSubject<string>('');

  /**
   * Emits product title
   */
  private _productTitle = new BehaviorSubject<string>('');

  get productTitle(): Observable<string> {
    return this._productTitle.asObservable();
  }

  setProductTitle(productTitle: string) {
    this._productTitle.next(productTitle);
  }

  get title(): Observable<string> {
    return this._title.asObservable();
  }

  /**
   * Notify if page title block message has been updated
   *
   * @param {string} title Page title block message
   */
  setTitle(title: string): void {
    this._title.next(title);
  }

  get description(): Observable<string> {
    return this._description.asObservable();
  }

  /**
   * Notify if page title block message has been updated
   *
   * @param {string} description sub header
   */
  setDescription(description: string): void {
    this._description.next(description);
  }
}

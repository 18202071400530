import { Component, ViewEncapsulation } from '@angular/core';
import {OAuthService, OAuthStorage} from 'angular-oauth2-oidc';

import { AppConfigService, AuthDataService, AuthService } from '../../core/services';
import * as translations from '../../shared/dictionary';
import {Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'wam-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardPageComponent {
  title: string;
  description: string;
  loginChecked = false;

  /**
   * text translations
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any;
  displayProducts: boolean;
  signinUrl: string;
  signoutUrl: string;
  year: number;
  aboutUsUrl = translations.About_Link;
  privacyUrl = translations.Privacy_Link;
  logoLink = translations.Logo_Link;
  copyRightLink = translations.CopyRight_Link;
  termsURL = translations.Terms_Conditions_Link;
  private magic_token= sessionStorage.getItem('magicToken');

  constructor(private authDataService: AuthDataService,
              private oauthService: OAuthService,
              private oauthStorage : OAuthStorage,
              private authService: AuthService,
              private cookie: CookieService,
              private router: Router,
              private configService: AppConfigService) {
    this.translations = translations;
    this.setTitleBlock();
    this.signinUrl = `${this.configService.signinUrl}`;
    this.signoutUrl = `${this.configService.signoutUrl}`;
    this.year = new Date().getFullYear();
    // const magic_token = sessionStorage.getItem('magicToken');
    const data = JSON.parse(sessionStorage.getItem('clientData'));
    if (!this.authService.clientData && data) {
      this.authService.clientData = data;
    }
    if (this.authService.clientData) {
      this.setClientData();
    }
  }

  setTitleBlock(): void {
    this.authDataService.title.subscribe((title: string) => {
      this.title = title;
    });

    this.authDataService.description.subscribe((description: string) => {
      this.description = description;
    })
  }

  showProdcuts() {
    this.displayProducts = true;
  }

  changePassword() {
    if (this.loginChecked = true) {
      this.router.navigate(['password/change']);
    }
  }

  updateProfile() {
    if (this.loginChecked = true) {
      this.router.navigate(['userprofile/update']);
    }
  }

  signout() {
    // this.oauthService.clientId = '30dd025e-eb3d-4f46-a322-76db620f93cf';
    this.oauthService.logoutUrl = 'https://ddc4c-login.lexisnexis.com/oauth2/sessions/logout';
    // this.oauthService.loginUrl = 'https://ddc4c-auth.lexisnexis.com/account/activate';
    this.oauthService.postLogoutRedirectUri = this.signinUrl + this.authService.clientData.applicationContextID;
    this.oauthService.redirectUri = this.signoutUrl + this.authService.clientData.applicationContextID;
    this.oauthService.logOut();
  }

  private setClientData() {
    if (this.authService.clientData.privacyURL) {
      this.privacyUrl = this.authService.clientData.privacyURL;
    }

    if (this.authService.clientData.copyrightURL) {
      this.copyRightLink = this.authService.clientData.copyrightURL;
    }

    if (this.authService.clientData.termsURL) {
      this.termsURL = this.authService.clientData.termsURL;
    }

    if (this.authService.clientData.aboutUsURL) {
      this.aboutUsUrl = this.authService.clientData.aboutUsURL;
    }
  }
}

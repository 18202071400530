import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AppConfigService } from '../services/app-config.service';
import { ABEResponse } from '../models/auth';



@Injectable({
  providedIn: 'root'
})

export class AciExcludeAbeFlagResolverService implements Resolve<any> {
  constructor(private authService: AuthService,
              private appConfigService: AppConfigService,
              private router: Router) {} // Inject Router service


  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const abeEvaluationName = `${this.appConfigService.progressiveLoginACIExcludeEvaluationName}`;
    console.log('Called Get Abe Flag in resolver...', route);
    let aci = route.queryParamMap.get('aci');
    console.log('aci value in resolver-from query param: ', aci);
    if (!aci) {
      aci = this.authService.appContextData.appContextId;
      console.log('aci value in resolver: ', aci);
    }
    this.authService.setSignInPageUrl(`${window.location.origin}${this.router.url}`);

    return this.authService.getAciExcludeFlagDetails(abeEvaluationName,aci).pipe(
      map((response: ABEResponse) => {
        return response.abeFlagValue;
      })
    );
  }
}

import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import * as translations from '../../shared/dictionary';
import { AuthService } from '../../core/services/auth.service';
import { IClientData } from '../../core/models/auth';

@Component({
  selector: 'wam-branding',
  templateUrl: './nonbranding.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class NonbrandingComponent {
  logo = translations.Branding_Logo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  /**
   * Client data
   */
  clientData$: Observable<IClientData>;
  locale: string;
  aci: string;
  sessionKey: string;

  constructor(private authService: AuthService, private route: ActivatedRoute) {
    this.translations = translations;

    this.locale = 'en-US';
    this.aci = 'noAppContext';
    this.sessionKey = 'clientData';

    const data = JSON.parse(sessionStorage.getItem(this.sessionKey));

    if(data && !(data.applicationContextID == this.aci && data.locale == this.locale)) {
      this.authService.clientData = null;
    }

    this.setLogoText();
  }

  private setLogoText(): void {
    const data = JSON.parse(sessionStorage.getItem(this.sessionKey));

    if (this.authService.clientData && this.authService.clientData.cn) {
      this.logo = this.authService.clientData.cn;
    } else if (data && !this.aci && !this.locale) {
      this.authService.clientData = data;
      this.logo = data.cn;
    } else {
      // Get client info
      this.clientData$ = this.authService.setClientData(this.aci, this.locale);

      this.clientData$.subscribe(clientData => {
        if (clientData && clientData.cn) {
          this.logo = clientData.cn;
        }
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, delay, retryWhen } from 'rxjs/operators';

import { ErrorService } from '../services';
import { Router } from '@angular/router';

/**
 * Maximum retry for infra failure
 */
export const maxRetries = 1;

/**
 * Delay before retrying
 */
export const delayMs = 1000;

/**
 * Intercepts HttpResponse on error
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private errorService: ErrorService, private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((error) =>
        error.pipe(
          mergeMap((err, index) => {
            if (err.status === 404) {
              this.router.parseUrl(`/error?code=${err.status}`);
            }
            throw err;
          })
        )
      )
    );
  }
}

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfigService, AuthService } from './core/services';
import { authConfigFactory } from './core/services/auth-config.factory';

@Component({
  selector: 'wam-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(private translate: TranslateService, private oauthService: OAuthService,
              private configService: AppConfigService,
              private authService: AuthService,
              private router: Router // Inject Router
  ) {
    const langs = [
      'de',
      'de-AT',
      'de-DE',
      'en',
      'en-AU',
      'en-CA',
      'en-GB',
      'en-HK',
      'en-IN',
      'en-MY',
      'en-NZ',
      'en-SG',
      'en-US',
      'en-ZA',
      'es',
      'es-ES',
      'fr',
      'fr-CA',
      'fr-FR',
      'it',
      'it-IT',
      'ja',
      'nl',
      'pt-BR',
    ];
    translate.addLangs(langs);
    translate.setDefaultLang('en-US');
    // Subscribe to router events
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd) // Only proceed if the event is NavigationEnd
    ).subscribe(() => {
      const aci =  this.authService.appContextData.appContextId;// Get the 'aci' value from auth service
      if (aci === 'lnps') {
        // ToDo: remove logs after testing
        console.log('Defaulting language to English (US) because aci is lnps from AppComponent');
        translate.use('en-US'); // Set the language to English (US) if aci equals 'lnps'
      } else {
        console.log('Using browser language');
        // Set the language based on the user's browser settings
        const browserLang = navigator.language;
        if (langs.includes(browserLang)) {
          translate.use(browserLang);
        }
      }
    });
    this.oauthService.configure(authConfigFactory(this.configService));
  }
}

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';
import { FooterModule } from '../../components/footer/footer.module';
import { BrandingModule } from '../../components/branding/branding.module';
import { PasswordPageComponent } from './password-page.component';
import { ResetPasswordModule } from '../../components/reset-password/reset-password.module';
import { CreatePasswordModule } from '../../components/create-password/create-password.module';
import { PasswordCreatedSuccessModule } from '../../components/password-created-success/password-created-success.module';
import { ActivateAccountModule } from '../../components/activate-account/activate-account.module';
import { PasswordPageRoutingModule } from './password-page-routing.module';
import { ChangePasswordModule } from 'src/app/components/change-password/change-password.module';
import { PasswordChangeSuccessModule } from 'src/app/components/change-password-success/password-change-success.module';
import { RecoverUserNameModule } from 'src/app/components/recover-username/recover-username.module';
import { RecoverUserNameSuccessModule } from 'src/app/components/recover-username-success/recover-username-success.module';
import { UserProfileModule } from 'src/app/components/user-profile/user-profile.module';
import {SignInProfileSuccessModule} from "../../components/signin-profile-success/signin-profile-success.module";

@NgModule({
  declarations: [PasswordPageComponent],
  imports: [
    SharedModule,
    FormsModule,
    PasswordPageRoutingModule,
    ReactiveFormsModule,
    FooterModule,
    BrandingModule,
    ResetPasswordModule,
    CreatePasswordModule,
    PasswordCreatedSuccessModule,
    ActivateAccountModule,
    ChangePasswordModule,
    PasswordChangeSuccessModule,
    RecoverUserNameModule,
    RecoverUserNameSuccessModule,
    UserProfileModule,
    SignInProfileSuccessModule
  ],
  exports: [PasswordPageComponent],
})
export class PasswordPageModule { }

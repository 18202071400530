import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from 'ln-templates-angular';

import * as translations from '../../shared/dictionary';
import { AppConfigService, AuthService } from '../../core/services';
import { IAuthMarketing, IBrandData } from '../../core/models/auth';
import {AppDataService} from "../../core/services/app-data.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'wam-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginPageComponent implements OnInit, OnDestroy {
  isXLargeScreen$: Observable<boolean>;
  authMarketing: IAuthMarketing;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;
  locale: any;
  aci: any;
  logo = translations.Branding_Logo;
  constructor(
    private _observer: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private configService: AppConfigService,
    private authService: AuthService,
    private appData: AppDataService,
    private route: ActivatedRoute) {

    this.route.queryParams.subscribe((params) => {
      this.locale = params['locale'];
      this.aci = params['aci'];
      this.logo = this.appData.getLogoText(this.aci,this.locale);
    });

    this.translations = translations;
    this.setTitle();
    this.setMetaTag();
  }

  ngOnInit(): void {
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
    this.setAuthMarketing();
  }

  setAuthMarketing() {
    const info = this.authService.getMarketingInfo();

    if (info) {
      this.authMarketing = info.authMarketing;
    }

    this.authService.marketingDataStore.subscribe((response: IBrandData) => {
      if (response) {
        this.authMarketing = response.authMarketing;
      }
    });
  }

  signUp(): void {
    console.log('Customer wants to sign up');
  }

  setTitle(): void {
    const productName =  this.logo.replace(/<([*/A-z][A-z]*)>/g, '');//'Lexis®';
    const pageTitleSuffix = 'Sign In | LexisNexis';
    const title = `${productName} - ${pageTitleSuffix}`;
    var x = document.getElementsByTagName("title")[0];
    x.innerHTML = title;
  }

  setMetaTag(): void {
    this.metaService.addTag(
      {
        name: 'description',
        content: this.translations.Login_Description_Meta_Tag,
      });
  }


  ngOnDestroy(): void {
    this.metaService.removeTag('name=description');
  }
}

import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'wam-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LogoutPageComponent {

  constructor() {
  }

}

import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { LoginFormModule } from '../../components/login/login-form.module';
import { FooterModule } from '../../components/footer/footer.module';
import { BrandingModule } from '../../components/branding/branding.module';
import { MarketingModule } from '../../components/marketing/marketing.module';
import { SignupModule } from '../../components/signup/signup.module';
import { SelfRegistrationModule } from '../../components/self-registration/self-registration.module';
import { LoginPageComponent } from './login-page.component';

@NgModule({
  declarations: [LoginPageComponent],
  imports: [
    SharedModule,
    LoginFormModule,
    FooterModule,
    BrandingModule,
    MarketingModule,
    SignupModule,
    SelfRegistrationModule,
  ],
  exports: [LoginPageComponent],
})
export class LoginPageModule { }

/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { IErrorData, IRedirectData } from '../../core/models/auth';
import { AuthService } from '../../core/services';

import * as translations from '../../shared/dictionary';

@Component({
  selector: 'wam-login-sso',
  templateUrl: './login-sso-form.component.html',
  styleUrls: ['./login-sso-form.component.less'],
  encapsulation: ViewEncapsulation.None,
})

export class LoginSSOFormComponent implements OnInit {
  inlineErrorDisplay: string;

  // form validation
  loginForm: FormGroup;
  emptyUserName = translations.LoginForm_emptyEmail;
  submitted = false;
  aci: string;
  showCustomerCareNumber: boolean;
  customerCare: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {
    this.translations = translations;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.aci = params['aci'];

      if (!this.authService.clientData) {
        this.authService.setClientData(params['aci'], params['locale']);
      }
    });
    this.createForm();
  }

  createForm(): void {
    this.loginForm = this.fb.group({
      userName: [
        '',
        Validators.required,
      ],
    });
  }

  get userNameControl(): FormControl {
    return this.loginForm.get('userName') as FormControl;
  }

  back() {
    this.authService.redirect(this.authService.getsignInPageUrl());
  }

  signIn(): void {
    this.showCustomerCareNumber = false;
    if (this.loginForm.valid) {
      this.submitted = true;
      this.customerCare = this.authService.clientData.csPhoneNumber;
      this.authService.ssoLogin(this.loginForm.value.userName, this.aci)
        .subscribe((response: IRedirectData) => {
          if (response.redirectUrl) {
            this.authService.redirect(response.redirectUrl);
          } else if (response.signinURL) {
            this.authService.redirect(response.signinURL);
          } else {
            this.authService.redirect(this.authService.clientData.landingPageURL);
          }
          this.submitted = false;
        }, (error: IErrorData) => {
          if (error.status === 400) {
            if (['451', '452'].includes(error.error.errorCode)) {
              this.router.navigate(['error'], { queryParams: { code: error.error.errorCode } });
            } else if (['453', '454', '455'].includes(error.error.errorCode)) {
              this.showCustomerCareNumber = true;
              const error = translations.LoginForm_SSO_Error;
              this.inlineErrorDisplay = error;
            } else if (['456'].includes(error.error.errorCode)) {
              this.showCustomerCareNumber = true;
              const error = translations.LoginForm_SSO_456;
              this.inlineErrorDisplay = error;
            } else {
              this.inlineErrorDisplay = this.emptyUserName;
            }
          } else {
            this.inlineErrorDisplay = translations.Login_SSO_Error;
          }
          this.submitted = false;
        });
    } else {
      this.loginForm.markAllAsTouched();
    }
  }
}

/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from 'ln-templates-angular';

import * as translations from '../../shared/dictionary';
import { AuthDataService, AuthService } from '../../core/services';
import { ICreatePasswordData, IErrorData } from '../../core/models/auth';

@Component({
  selector: 'wam-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class CreatePasswordComponent implements OnInit, OnDestroy {
  isXLargeScreen$: Observable<boolean>;
  token: string;
  password: string;
  confirmpassword: string;

  inlineErrorDisplay: string;
  submitted = false;
  authForm: FormGroup = new FormGroup({});
  _patternErrorMap: { [key: string]: boolean };
  showPassword = false;
  srPasswordText = translations.LoginForm_PasswordHidden;
  passwordType = 'password';

  showConfirmPassword = false;
  srConfirmPasswordText = translations.LoginForm_PasswordHidden;
  confirmPasswordType = 'password';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(
    private _observer: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authDataService: AuthDataService,
    private fb: FormBuilder) {
    this.translations = translations;
    this.setTitle();
    this.setMetaTag();
    this.setTitleBlock();
    this.createForm();
  }

  ngOnInit(): void {
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      this.authService.loginChallenge = params['login_challenge'];
    });
  }

  get c() {
    return this.authForm.controls;
  }

  createForm() {
    this.authForm = this.fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          this.cannotContainSpace,
          this.minPatternValidator(),
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
        ],
      ],
    },{
      validators: this.matchValidator,
    }
  );
      this.authForm.get('password').valueChanges.subscribe(() => {
        this.authForm.get('confirmPassword').updateValueAndValidity();
    });
  }

  createPassword(): void {
    this.inlineErrorDisplay = '';
    if (this.authForm.valid) {
      this.submitted = true;
      this.authService.createUsersPassword(this.token, this.authForm.get('password').value)
        .subscribe((response: ICreatePasswordData) => {
          this.authService.setSignInPageUrl(response.redirectUrl);
          this.router.navigate(['password/success']);
        }, (err: IErrorData) => {
          if (err && err.error && err.error.errorCode) {
            const code: string = err.error.errorCode;
            if (code === '452') {
              this.inlineErrorDisplay = translations.Password_Required;
            } else if (code === '451' || code === '457') {
              this.router.navigate(['error'], { queryParams: { code } });
            } else if (code === '453') {
              this.inlineErrorDisplay = translations.Password_strength_rules_not_matched;
            } else if (code === '454') {
              this.inlineErrorDisplay = translations.Password_contains_username;
            } else if (code === '455') {
              this.inlineErrorDisplay = translations.Password_contains_reverse_username;
            } else if (code === '456') {
              this.inlineErrorDisplay = translations.Password_should_not_same_as_last_five_passwords;
            } else if (code === '461') {
              this.inlineErrorDisplay = translations.Password_policy_violation;
            }
          }
        });
    } else {
      this.inlineErrorDisplay = translations.Password_empty;
    }
  }

  setTitle(): void {
    const productName = 'Lexis®';
    const pageTitleSuffix = 'Create password | LexisNexis';
    const title = `${productName} - ${pageTitleSuffix}`;
    this.titleService.setTitle(title);
  }

  back() {
    this.location.back();
  }

  backToSignIn() {
    if (this.isNullUndefinedOrEmptyString(this.authService.loginChallenge)) {
      this.navigateToLandingPage();
      return;
    }
    //If the login challenge exists, forward to the auth ui sign in
    this.navigateToLogin();
  }

  navigateToLogin() {
    this.router.navigate(
      ['login'],
      {
        queryParams: {
          login_challenge: this.authService.loginChallenge,
        }
      },
    );
  }

  isNullUndefinedOrEmptyString(value: string){
    return (value == null || value.length === 0 || 'null' === value || 'undefined' === value);
  }

  navigateToLandingPage() {
    //Redirect the user to the landing page which will forward to the login page if not authenticated.
    const signInUrl = this.authService.clientData.landingPageURL;
    this.authService.redirect(signInUrl);
  }

  setMetaTag(): void {
    this.metaService.addTag(
      {
        name: 'description',
        content: this.translations.Login_Description_Meta_Tag,
      });
  }

  setTitleBlock(): void {
    this.authDataService.setTitle(this.translations.Create_Password_Heading);
    this.authDataService.setDescription(this.translations.Create_Password_SubHeading);
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=description');
  }

  private minPatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const digitRegExp = new RegExp('(?=.*[0-9])');
      const upperCaseRegExp = new RegExp('(?=.*[A-Z])');
      const lowerCaseRegExp = new RegExp('(?=.*[a-z])');
      const specialCharRegExp = new RegExp('^[a-zA-Z0-9!$%|#&\'*-?^_`{}~.@]+$');

      // These regexes are used to check patterns only when the value has characters outside the other patterns

      // Used to check special characters: includes characters outside alphanumeric
      const nonAlphaNumericRegExp = new RegExp('(?=.*[^a-zA-Z0-9])');

      // Used to check digits: includes characters outside special characters and alphabets
      const nonSpecialAndAlphaRegExp = new RegExp('(?=.*[^a-z-A-Z!$&\'*?^_`{}~.@])');

      // Used to check upper cases: includes characters outside special characters, numeric and lower case
      const nonSpecialLowerAlphaNumericRegExp = new RegExp('(?=.*[^a-z0-9!$&\'*-?^_`{}~.@])');

      // Used to check lower cases: includes characters outside special characters, numeric and upper case
      const nonSpecialUpperAlphaNumericRegExp = new RegExp('(?=.*[^0-9A-Z!$&\'*-?^_`{}~.@])');

      let validCount = 0;
      this._patternErrorMap = {
        nonAlphaNumeric: true,
        nonSpecialAndAlpha: true,
        nonSpecialLowerAlphaNumeric: true,
        nonSpecialUpperAlphaNumeric: true,
      };

      let hasInvalidPattern = false;

      if (nonSpecialAndAlphaRegExp.test(control.value)) {
        this._patternErrorMap.nonSpecialAndAlpha = false;

        // Check digit
        if (digitRegExp.test(control.value)) {
          validCount++;
        } else {
          this._patternErrorMap.requiresDigit = true;
          hasInvalidPattern = true;
        }
      }

      if (nonSpecialLowerAlphaNumericRegExp.test(control.value)) {
        this._patternErrorMap.nonSpecialLowerAlphaNumeric = false;

        // Check uppercase
        if (upperCaseRegExp.test(control.value)) {
          validCount++;
        } else {
          this._patternErrorMap.requiresUppercase = true;
          hasInvalidPattern = true;
        }
      }

      if (nonSpecialUpperAlphaNumericRegExp.test(control.value)) {
        this._patternErrorMap.nonSpecialUpperAlphaNumeric = false;

        // Check lowercase
        if (lowerCaseRegExp.test(control.value)) {
          validCount++;
        } else {
          this._patternErrorMap.requiresLowercase = true;
          hasInvalidPattern = true;
        }
      }

      if (nonAlphaNumericRegExp.test(control.value)) {
        this._patternErrorMap.nonAlphaNumeric = false;

        // Check special characters
        if (specialCharRegExp.test(control.value)) {
          validCount++;
        } else {
          this._patternErrorMap.requiresSpecialChars = true;
          hasInvalidPattern = true;
        }
      }

      return validCount > 2 && !hasInvalidPattern ? null : { pattern : validCount, hasInvalidPattern };
    };
  }

  private matchValidator(control: AbstractControl) {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;

    if (!confirmPassword || !confirmPassword.length) {
      return null;
    }

    if (confirmPassword.length < 8) {
      control.get('confirmPassword').setErrors({ minLength: true });
    } else {
      if (password !== confirmPassword) {
        control.get('confirmPassword').setErrors({ mismatch: true });
      } else {
        return null;
      }
    }
  }

  private cannotContainSpace(control: AbstractControl) {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }
    return null;
  }

  togglePasswordMask(): void {
    this.showPassword = !this.showPassword;
    this.passwordType = this.showPassword ? 'text' : 'password';
    // text for screen reader only
    this.srPasswordText = this.showPassword ? translations.LoginForm_PasswordShown : translations.LoginForm_PasswordHidden;
  }

  toggleConfirmPasswordMask(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
    this.confirmPasswordType = this.showConfirmPassword ? 'text' : 'password';
    // text for screen reader only
    this.srConfirmPasswordText = this.showConfirmPassword ? translations.LoginForm_PasswordShown : translations.LoginForm_PasswordHidden;
  }
}

import { Component, ViewEncapsulation } from '@angular/core';

import { AuthDataService } from '../../core/services';
import * as translations from '../../shared/dictionary';

@Component({
  selector: 'wam-activate-account-page',
  templateUrl: './activate-account-page.component.html',
  styleUrls: ['./activate-account-page.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountActivatePageComponent {
  title: string;
  description: string;

  /**
   * text translations
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any;

  constructor(private authDataService: AuthDataService) {
    this.translations = translations;
    this.setTitleBlock();
  }

  setTitleBlock(): void {
    this.authDataService.title.subscribe((title: string) => {
      this.title = title;
    });

    this.authDataService.description.subscribe((description: string) => {
      this.description = description;
    })
  }
}

import { APP_INITIALIZER, FactoryProvider } from '@angular/core';

import { AppConfigService } from './app-config.service';

/**
 * @param configService
 */
// eslint-disable-next-line
export function loadConfig(configService: AppConfigService): any {
  return () => configService.loadAppConfig();
}

export const AppInitializerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: loadConfig,
  deps: [AppConfigService],
  multi: true,
};

import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { FooterModule } from '../../components/footer/footer.module';
import { BrandingModule } from '../../components/branding/branding.module';
import { MarketingModule } from '../../components/marketing/marketing.module';
import { LoginSSOPageComponent } from './login-sso-page.component';
import { LoginSSOFormModule } from '../../components/login-sso/login-sso-form.module';

@NgModule({
  declarations: [LoginSSOPageComponent],
  imports: [
    SharedModule,
    LoginSSOFormModule,
    FooterModule,
    BrandingModule,
    MarketingModule,
  ],
  exports: [LoginSSOPageComponent],
})
export class LoginSSOPageModule { }

import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { BrandingComponent } from './branding.component';

@NgModule({
  declarations: [BrandingComponent],
  imports: [SharedModule],
  exports: [BrandingComponent],
})
export class BrandingModule { }

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from 'ln-templates-angular';

import * as translations from '../../shared/dictionary';
import { OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { AuthService, ErrorService, AuthDataService, AppConfigService } from '../../core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {IErrorData, IUserProductSubscriptions} from "../../core/models/auth";
import { authProductConfigFactory } from '../../core/services/auth-product-config.factory';

@Component({
  selector: 'wam-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductSelectionComponent implements OnInit, OnDestroy {
  isXLargeScreen$: Observable<boolean>;
  loginChecked = false;
  loginId: string;
  signoutUrl = '';
  aci = '';
  success = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  products = [];
  productTitle: string;

  constructor(
    private _observer: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private authService: AuthService,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private location: Location,
    private authDataService: AuthDataService,
    private router: Router,
    private oauthService: OAuthService,
    private configService: AppConfigService) {

    this.oauthService.configure(authProductConfigFactory(this.configService));
    // Handle OAuth errors
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        this.router.navigate(['error'], { queryParams: { code: String('400') } });
      }
    });
    this.oauthService.loadDiscoveryDocumentAndLogin().then(() => {
      if (this.oauthService.hasValidAccessToken()  && this.oauthService.hasValidIdToken()) {
        this.loginChecked = true;
        this.setTitle();
        this.setMetaTag();
      }
      this.getUserProductSubscriptions();
    });
    this.translations = translations;
    this.signoutUrl = `${this.configService.signoutUrl}`;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!this.authService.clientData) {
        this.authService.setClientData(params['aci'], params['locale']);
      }
    });
    this.setCustomerCareNumber();
    if (this.authService.clientData) {
      this.productTitle =   this.authService.clientData.cn;
    }

    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
  }

  getUserProductSubscriptions() {
    if (this.oauthService.hasValidAccessToken()) {
      const access = this.oauthService.getAccessToken();
      const userPermId = this.getUserPermId();
      this.authService.getUserProductSubscriptions(userPermId, access)
        .subscribe((response: IUserProductSubscriptions) => {
          this.loginId = response.loginId;
          const products = response.productSubscriptions || [];
          this.products = [];
          while (products.length > 0) {
            const c = products.splice(0, 3);
            this.products.push(c);
          }
          if (response.loginId == null || response.loginId === '' || typeof response.loginId === 'undefined'
          || this.productTitle == null || this.productTitle === '' || typeof this.productTitle === 'undefined') {
            this.router.navigate(['error'], { queryParams: { code: String('400') } });
          }
          this.setTitleBlock();
          this.success = true;
        }, (err: IErrorData) => {
          this.router.navigate(['error'], { queryParams: { code: String(err.status) } });
        });
    }
  }

  redirectToProduct(url: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  public getUserPermId(): string {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    const sub = 'sub';
    return claims[sub];
  }

  setTitle(): void {
    const productName = 'Lexis®';
    const pageTitleSuffix = 'Product Access | LexisNexis';
    const title = `${productName} - ${pageTitleSuffix}`;
    this.titleService.setTitle(title);
  }

  setMetaTag(): void {
    this.metaService.addTag(
      {
        name: 'description',
        content: this.translations.Login_Description_Meta_Tag,
      });
  }

  setTitleBlock(): void {
    this.authDataService.setTitle("product.selection.title");
    this.authDataService.setDescription("product.selection.desc");
    this.authDataService.setProductTitle(this.productTitle);
  }

  back() {
    this.authService.redirect(this.authService.getsignInPageUrl());
  }

  signout() {
    this.oauthService.postLogoutRedirectUri = this.signoutUrl + this.authService.clientData.applicationContextID + '&clearRM=true';
    this.oauthService.logOut();
  }

  private setCustomerCareNumber() {
    if (this.authService.clientData && this.authService.clientData.csPhoneNumber) {
      //this.customerCareNumber = this.authService.clientData.csPhoneNumber;
    }
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=description');
  }
}

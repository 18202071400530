import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';
import { FooterModule } from '../../components/footer/footer.module';
import { NonbrandingModule } from '../../components/nonbranding/nonbranding.module';
import { DashboardPageComponent } from './dashboard-page.component';
import { DashboardPageRoutingModule } from './dashboard-page-routing.module';
import { DashboardModule } from 'src/app/components/dashboard/dashboard.module';

@NgModule({
  declarations: [DashboardPageComponent],
  imports: [
    SharedModule,
    FormsModule,
    DashboardPageRoutingModule,
    ReactiveFormsModule,
    FooterModule,
    NonbrandingModule,
    DashboardModule
  ],
  exports: [DashboardPageComponent],
})
export class DashboardUserModule { }

import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { NonbrandingComponent } from './nonbranding.component';

@NgModule({
  declarations: [NonbrandingComponent],
  imports: [SharedModule],
  exports: [NonbrandingComponent],
})
export class NonbrandingModule { }

import { NgModule } from '@angular/core';

import { OAuthModule } from 'angular-oauth2-oidc';
import { SharedModule } from '../../shared/shared.module';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    SharedModule,
    OAuthModule.forRoot(),
  ],
  exports: [DashboardComponent],
})
export class DashboardModule { }

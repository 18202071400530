import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { PasswordCreatedSuccessComponent } from './password-created-success.component';

@NgModule({
  declarations: [PasswordCreatedSuccessComponent],
  imports: [SharedModule],
  exports: [PasswordCreatedSuccessComponent],
})
export class PasswordCreatedSuccessModule { }

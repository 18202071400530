import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AppConfigService } from '../services/app-config.service';
import { ABEResponse } from '../models/auth';
 
 
@Injectable({
  providedIn: 'root'
})

export class AbeFlagResolverService implements Resolve<any> {
  constructor(private authService: AuthService,
                private appConfigService: AppConfigService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const abeEvaluationName = `${this.appConfigService.progressiveLoginABEEvaluationName}`;
    console.log('Called Get Abe Flag in resolver...', route);
    return this.authService.getAbeFlagDetails(abeEvaluationName).pipe(
      map((response: ABEResponse) => {
        return response.abeFlagValue;
      })
    );
  }
}